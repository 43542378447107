.block {
  display: block;
  height: 30px;
  width: 100%;
  // margin-bottom: 10px;

  &:hover {
    .SideLink svg {
      fill: #007bff !important;
    }
    .SideLink__text {
      color: #007bff !important;
    }
  }

  .SideLink__text {
    color: #102a43 !important;
  }
}
.activeLink {
  svg {
    fill: #007bff !important;
  }
  .SideLink svg {
    fill: #007bff !important;
  }
  .slidingHighlighter {
    border-left: 4px solid #007bff !important;
  }
  .SideLink__text {
    color: #007bff !important;
  }
}

.SideLink {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 0.5s ease;

  &--child {
    margin: 0;
    width: 100%;
    height: 25px;
    font-size: 0.85em;
  }

  &:hover {
    .hoverOvelay {
      pointer-events: all;
      opacity: 1;
      svg {
        fill: #007bff;
      }
      span {
        color: #007bff;
      }
    }
  }

  .slidingHighlighter {
    position: absolute;
    height: 40px;
    top: 0;
    z-index: -1;
    width: 45px;
    left: 0;
    border-left: 4px solid transparent;
    transition: all 0.5s ease;
    transform-origin: left;
  }

  &__text {
    opacity: 0;
    max-width: 0;
    margin-left: 52.5px;
    transform-origin: left;
    display: flex;
    font-size: 1em;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease;
    color: #102a43;
    pointer-events: none;
  }

  .dropdownRotateIcon {
    opacity: 0;
  }
  &--open {
    .dropdown {
      opacity: 1 !important;
    }
    .slidingHighlighter {
      width: 100%;
      border-radius: 0;
    }
    .SideLink__text {
      opacity: 1 !important;
      animation: increaseSize 0.5s ease forwards;
      transition: opacity 0.3s ease 0.5s !important;
      pointer-events: all;
    }
    .notification--outer {
      opacity: 0 !important;
      pointer-events: none !important;
    }
    .dropdownRotateIcon {
      opacity: 1;
    }
  }

  &--dropdown {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mainLink {
    display: flex;
    width: 100%;
    height: 30px;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:hover {
      .hoverOvelay {
        pointer-events: all;
        opacity: 1;
        svg {
          fill: #007bff;
        }
        span {
          color: #007bff;
        }
      }
      svg {
        fill: #007bff !important;
      }

      .SideLink__text {
        color: #007bff;
      }
    }
  }

  .childLinksHolder {
    width: 100%;
    transform: scaleY(0);
    // height: 0;
    opacity: 0;
    transition: all 0.5s ease;
    transform-origin: top;
    &--expand {
      opacity: 1 !important;
      animation: expand 0.5s ease forwards;
      transition: opacity 0.3s ease 0.5s !important;
    }
  }

  svg {
    width: 18px;
    height: 18px;
    // width: 22.5px;
    // height: 22.5px;
    background-color: transparent;
    // margin: 0 calc((10vh - 4vh)/2);
    margin: 0 21.25px;
    margin-right: 12px;
    position: absolute;
    fill: #9fb3c8;
  }

  .dropdown {
    width: 10px;
    position: absolute;
    right: 20px;
    opacity: 0;
  }

  .sideItemNotification {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    color: #fff;

    &.notification--outer {
      position: absolute;
      top: 10%;
      left: calc(4vh - 5px);
      transition: all 0.2s ease;
      opacity: 1;
      pointer-events: all;
      margin-left: 5px;
    }
  }
}

.childLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 45px;
  transition: all 0.5s ease;
  position: relative;
  &:hover.SideLink--child {
    color: #007bff !important;
  }
}

.childLinksItem {
  display: inline-block;
  &__active {
    color: #007bff;
    font-weight: 500;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes increaseSize {
  from {
    transform: scaleX(0);
    max-width: 0;
  }
  to {
    transform: scaleX(1);
    max-width: 200px;
  }
}

.dropdownRotateIcon {
  transform: rotate(180deg);
  transition: all 0.3s ease;
  right: 10px !important;
  width: 10px !important;
}

.sideLinkExpanded {
  .dropdownRotateIcon {
    transform: rotate(0deg);
    opacity: 1;
  }
  &:hover {
    .slidingHighlighter {
      background-color: unset;
    }
    .mainLink {
      color: #fff;
    }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes expand {
  from {
    transform: scaleY(0);
    height: 0;
  }
  to {
    transform: scaleY(1);
    height: 20vh;
  }
}

.delLink {
  margin-left: 15px;
  cursor: pointer;
  height: 12px !important;
  width: 12px !important;
  position: absolute;
  right: 15px;
}

.hoverOvelay {
  opacity: 0;
  width: 100%;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(217, 226, 236, 0.2);
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  transition: all 0.4s ease;
  z-index: 100;
  min-width: 200px;
  svg {
    margin-right: 10.5px;
    position: relative !important;
    margin-left: 10.5px !important;
  }
  span {
    color: #007bff;
    font-size: 14px;
  }
}
