.QRScanner{
    width: fit-content;
    transition: all 0.5 ease-in-out;
   
    &--load{
    filter: blur(8px);
        --webkit-filter: blur(8px);
        --moz-filter: blur(8px);
        --o-filter: blur(8px);
        overflow: hidden;
        display: block;
    }

}