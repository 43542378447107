.specialMenu {
    width: 30px;
    /*height: 15px;*/
    /*background: red;*/
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    height: 16px;
    justify-content: space-between;
    /*position: absolute;*/
}

.menuGroup {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2px;
}

.circle {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #707070;
    margin-right: 3px;
}

.rectangle {
    background-color: #707070;
    width: calc(100% - 5px);
    height: 4px;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.menuGroup:nth-of-type(2) .rectangle {
    width: calc(100% - 13px);
}

.menuGroup:last-of-type {
    margin-bottom: 0;
}
