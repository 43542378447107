.multiOptionToolTip{
    background: #334E68;
    border-radius: 4px;   
    color: #fff;
    padding: 3px;
}

.selectedMultiOption{
    height: 24px;
    background: #F0F4F8;
    border-radius: 4px;
    padding: 2px 4px;
    cursor: pointer;
    // margin-bottom: 0 !important;
    // margin-right: 15px;
    span{
        color: #102a43;
        font-size: 14px;
        
    }
}


