.BC {
  &--input {
    border: 1px solid #bcccdc;
    margin-bottom: 24px;
    //   width: fit-content !important;
    .ADropDown__LabelContainer {
      &--text {
        margin-right: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        // width: 100px;
      }
    }
  }
  &--popup-content {
    left: 20px !important;
    width: initial !important;
  }
}
