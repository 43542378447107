.selectTag {
    .---item {
        position: relative;
        padding: 0.5rem;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: #fbfcff;
        }
    }
}
