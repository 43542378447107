.dropdown__container {
    // &:hover {
    //     .__list {
    //         display: block;
    //     }
    // }
    // .__list {
    //     display: none;
    // }
    .__title,
    .__listitem {
        color: #102a43;
        svg {
            fill: #102a43;
        }
        &:hover {
            color: #006ee5;
            svg {
                fill: #006ee5;
            }
        }
    }
}
