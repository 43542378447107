.orderPreviewGroup{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 10px;
    border-bottom: 1px solid #D9E2EC;
    .rightPreview{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg{
            margin-right: 12px;
        }
        .rightPreviewText{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            h2{
                font-size: 14px;
                color: #102a43;
                font-weight: 500;
                margin-bottom: 4px;
            }
        }

    }
    .leftPreviewText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        .leftPreviewLink{
            font-size: 13px;
            color: #007bff;
            transition: all 0.5s ease;
            &:hover{
                color: #006ee5;
            }
        }
    }
}

.orderPreviewGroup:last-child{
    border: none;
}

.previewText{
    font-size: 13px;
    color: #334e68;
    line-height: 16px;
}


