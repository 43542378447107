#browseFile{
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.fileInputContainer{
    width: 100%;
    height: 36px;
    background: #E5F1FF80;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    p{
        color: #243B53;
        font-size: 12px;
        span{
            color: #007BFF;
            cursor: pointer;
        }
    }
}

.dragActive{
    background: #E5F1FF;
    border: 1px dashed #007BFF;
    opacity: 1;
}

.fileLabel{
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #486581;
    margin-bottom: 4px;
}