.SelectedArtwork {
  &__Container {
    width: 100px;
    min-height: 80px;
    max-height: 120px;
    border-radius: 8px;
    position: relative;
    border: 1px solid #d9e2ec;
    * > img {
      height: 80px;
      width: 100px;
      border-radius: 4px 4px 0px 0px;
      object-fit: cover;
    }
  }
  &__clearSvg {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
    height: 20px;
    width: 20px;
    box-shadow: 0px 2px 4px #102a4320;
    border-radius: 50%;
  }
}
