$primary-color: #9446ed;
$nav-bar-color: #2a5985;

// break-point variables

/*Btn utility classes*/
.Btn {
  &--Primary {
    transition: all 0.5s ease;
    color: #fff;
    background-color: #007bff !important;
    &:hover {
      background-color: #006ee5 !important;
    }
  }

  &--Secondary {
    transition: all 0.5s ease;
    color: #007bff !important;
    background-color: #e5f1ff !important;
    &:hover {
      color: #0062cc !important;
    }
  }

  &--Default {
    transition: all 0.5s ease;

    color: #102a43 !important;
    background-color: #f0f4f8 !important;
    &:hover {
      color: #243b53 !important;
      background-color: #d9e2ec !important;
    }
  }

  &--PDanger {
    transition: all 0.5s ease;

    color: #fff !important;
    background-color: #ef4e4e !important;
    &:hover {
      background-color: #e12d39 !important;
    }
  }

  &--SDanger {
    transition: all 0.5s ease;

    color: #ef4e4e !important;
    background-color: transparent !important;
    border: 1px solid #9fb3c8 !important;
    &:hover {
      border: 1px solid #ef4e4e !important;
    }
  }

  &--SBtn {
    transition: all 0.5s ease;

    color: #007bff80 !important;
    cursor: pointer;

    &:hover {
      color: #007bff !important;
    }
  }
}

.Toaster__manager-top-left {
  z-index: 33000 !important;
}
.Toaster__manager-top-right {
  z-index: 33000 !important;
}

.Toaster {
  z-index: 33000 !important;
}

.__hoverIcon {
  width: fit-content;
  padding: 5px !important;
  border-radius: 2px;
  cursor: pointer;

  &.disable {
    &:hover {
      background: #e5f1ff5f;
    }
    svg {
      cursor: not-allowed;
    }
  }
  &:hover {
    background: #e5f1ff;
  }

  svg {
    fill: #007bff;
  }
}

.production-content {
  .table-container td {
    overflow: visible !important;
  }
}

.chakra-input {
  border-width: 1px;
}

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
  scroll-behavior: smooth;
  scroll-snap-type: proximity;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
