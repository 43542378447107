.banner-container {
    position: fixed;
    display: flex;
    align-items: flex-end;
    top: 0;
    left: 0;
    z-index: 9;
    height: 194px;
    width: 100%;
    // background: #fbfcfe;
    background-color: transparent;
    padding-bottom: 1rem;
    opacity: 0;
    // transform: translateY(0%);
    // transition: all 1s ease-in-out;
    // &.banner-container--show {
    //     opacity: 1;
    //     transform: translateY(5%);
    // }
    // &.banner-container--hide {
    //     opacity: 0;
    //     transform: translateY(0%);
    // }
    .--content {
        position: fixed;
        // width: calc(100% - 12.5%);
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
