.progressCircleContainer {
    position: relative;

    .progress {
        position: relative;
    }

    // .progressbar-back {
    //     stroke: #eee;
    //     stroke-width: 1;
    //     fill: none;
    // }
    // .progressbar-line {
    //     stroke: #55b9e6;
    //     stroke-width: 10;
    //     fill: none;
    //     stroke-linecap: round;
    //     transform: rotate(-90deg);
    //     transform-origin: 50px 50px;
    //     stroke-dasharray: 304.777px, 304.777px;
    //     stroke-dashoffset: 304.777px;
    //     transition: stroke-dashoffset 1s ease;
    // }
    .progressbar-marker {
        fill: #55b9e6;
        transform-origin: 50% 50%;
        transform: rotate(0deg);
        transition: transform 1s ease;
    }
    // .--reading {
    //     position: absolute;
    //     width: 200px;
    //     text-align: center;
    //     top: 90px;
    //     font-size: 20px;
    //     color: rgb(85, 185, 230);
    // }

    input#r1:checked ~ .progress .progressbar-marker {
        transform: rotate(0deg);
    }
    input#r2:checked ~ .progress .progressbar-marker {
        transform: rotate(90deg);
    }
    input#r3:checked ~ .progress .progressbar-marker {
        transform: rotate(180deg);
    }
    input#r4:checked ~ .progress .progressbar-marker {
        transform: rotate(270deg);
    }
    input#r5:checked ~ .progress .progressbar-marker {
        transform: rotate(360deg);
    }

    input#r1:checked ~ .progress .progressbar-line {
        stroke-dashoffset: 304.777px;
    }
    input#r2:checked ~ .progress .progressbar-line {
        stroke-dashoffset: 228.582px;
    }
    input#r3:checked ~ .progress .progressbar-line {
        stroke-dashoffset: 152.388px;
    }
    input#r4:checked ~ .progress .progressbar-line {
        stroke-dashoffset: 76.194px;
    }
    input#r5:checked ~ .progress .progressbar-line {
        stroke-dashoffset: 0px;
    }

    .progress-ring {
    }

    .--reading {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;

        p {
            font-family: Inter;
            font-weight: bold;
            font-size: 12px;
        }
        // top: calc(100% / 2);
        // left: calc(100% / 3);
        // margin-right: calc(100% / 2);
        // position: absolute;
        // width: 200px;
        // text-align: center;
        // top: 90px;
        // font-size: 20px;
        // color: rgb(85, 185, 230);
    }
    .progress-ring {
        &__circle {
            transition: 0.35s stroke-dashoffset;
            transform: rotate(90deg);
            transform-origin: 50% 50%;
            fill: transparent;
        }
        &__dot {
            transform-origin: 50% 50%;
            transform: rotate(0deg);
            transition: 0.35s stroke-dashoffset;
        }
    }
}
