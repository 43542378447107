@import "~@samedaycustom/assets/responsive-helper.scss";

.Message {
  &__Container {
    width: fit-content;
    &--Incoming {
      align-self: flex-start;
      @include respondTo(small-medium) {
        max-width: 85%;
        min-width: 250px;
      }
      max-width: 560px;
      margin-bottom: 15px;
      min-width: 250px;
      // min-width: 336px;
      .Message__bubble {
        background-color: #e5f1ff;
        border-radius: 12px 12px 12px 0px;
        word-wrap: break-word;
        align-self: flex-start;
        width: fit-content;
        min-width: 250px;
        * > a {
          color: #007bff !important;
          text-decoration: underline !important;
        }
      }
      .timeStamp {
        font-size: 13px;
        font-weight: 500;
        margin-top: 4px;
        color: #829ab1;
        display: block;
        text-align: left;
        padding-right: 5px;
      }
    }
    &--Outgoing {
      align-self: flex-end;
      max-width: 560px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      width: fit-content;
      min-width: 250px;
      @include respondTo(small-medium) {
        max-width: 85%;
        min-width: 250px;
      }
      .Message__bubble {
        border: 1px solid #d9e2ec;
        background-color: #fff;
        border-radius: 12px 12px 0px 12px;
        transition: all 0.5s ease;
        word-wrap: break-word;
        align-self: flex-end;
        width: 100%;
        min-width: 250px;
        * > a {
          color: #007bff !important;
          text-decoration: underline !important;
        }
      }
      .timeStamp {
        font-size: 13px;
        font-weight: 500;
        margin-top: 4px;
        color: #829ab1;
        display: block;
        text-align: right;
        padding-right: 5px;
      }
    }
  }
  &__bubble {
    padding: 12px 12px;
  }
  &__tagContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    span {
      color: #007bff;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.timeStamp {
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
  color: #829ab1;
  display: block;
  text-align: right;
  padding-right: 5px;
}

.MessageFileGroup {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;

  img {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    margin-right: 5px;
    object-fit: cover;
  }
  .messageFileDetails {
    a {
      text-decoration: none;
      display: block;
      color: #007bff;
    }
    span {
      display: block;
      &:nth-child(2) {
        font-size: 12px;
        font-weight: 500;
        color: #334e68;
      }
    }
  }
}

.oldMessage {
  width: 100%;
  height: 60px;
  border: 1px solid #bcccdc40;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: 3px solid transparent;
  padding: 5px 15px;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    // height: 100%;
    white-space: nowrap;
  }
}
.flash {
  .Message__bubble {
    transition: all 0.5s ease !important;
    animation: flashAnim 1.2s ease 0s 1 forwards;
  }
}

@keyframes flashAnim {
  to {
    background-color: #bcccdc30;
  }
}

.oldImages {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
}

.chat-message-formatting {
  white-space: pre-wrap;
  word-wrap: break-word;
}