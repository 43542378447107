.radio-button {
  display: flex;
  position: relative;
  background-color: #fff;
  border: 1px solid #bcccdc;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  // box-sizing: border;
  cursor: pointer;
  transition: opacity 0.7s cubic-bezier(0.42, 0, 0.58, 2);

  &.inverted {
    background-color: none;
    border: none;

    &.show {
      background-color: #007bff;
      border: none;
    }

    &::after {
      background-color: #fff;
    }
  }
  &::after {
    position: absolute;
    display: block;
    content: "";
    height: calc(100% / 2.5);
    width: calc(100% / 2.5);
    border-radius: inherit;
    background-color: #007bff;
    transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 2);
  }
  &.show {
    border: 1px solid #007bff;
    animation: fadeRadio 0.3s ease-in;
    &::after {
      animation: fadeRadioShow 0.5s cubic-bezier(0.42, 0, 0.58, 2);
    }
  }
  &.hide {
    &::after {
      opacity: 0;
    }
  }
}

@keyframes fadeRadio {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeRadioShow {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
