@import "~@samedaycustom/assets/responsive-helper.scss";
.HorizontalNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 5px;

  .navItem {
    @include respondTo(extra-large) {
      // padding: 7px !important;
      // font-size: 12px;
      // display: flex;
      // align-items: center;
      h1 {
        // margin-right: 10px !important;
        // font-size: 23rem !important;
      }
    }
    @include respondTo(small) {
      padding: 7px !important;
      font-size: 12px;
      display: flex;
      align-items: center;
      h1 {
        margin-right: 7px !important;
      }
    }

    @include respondTo(tiny) {
      font-size: 10px;
    }
  }

  .rightContent {
    display: flex;
  }

  .navigation {
    .navigationList {
      display: flex;
      margin-bottom: 5px;
      .navItem {
        // @include respondTo(small){
        // padding: 5px 10px !important;
        // }
        display: flex;
        flex-direction: row;
        padding: 0px 15px;
        // padding-right: 25px;
        border-right: 0.5px solid #adadad;
        // padding-left: 25px;

        cursor: pointer;
        font-family: Inter;
        font-weight: 500;
        &--active {
          h1 {
            color: #007bff !important;
          }
        }

        &:hover {
          h1 {
            color: #007bff;
          }
        }

        &:first-child {
          // padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
        h1 {
          display: inline-block;
          // margin-right: 15px;
          transition: all 0.5s ease;
          color: #645c5c;
          // margin-bottom: 0px;
          font-size: inherit;
        }
        // span {
        // 	display: flex;
        // 	align-items: center;
        // 	justify-content: center;
        // 	height: 20px;
        // 	width: 30px;
        // 	background-color: #d9e2ec;
        // 	border-radius: 10px;
        // 	// text-align: center;
        // 	color: #243b53;
        // 	font-size: 12px;
        // }
      }
    }

    .sliderTrack {
      width: 100%;
      margin-top: 8px;
      position: relative;

      .slider {
        height: 5px;
        width: 0;
        background-color: #007bff;
        transition: all 0.5s ease;
        position: absolute;
      }
    }
  }
}
