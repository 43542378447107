
.searchInputGroup {
	height: 40px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	input {
		border: 1px solid #bcccdc;
		outline: none !important;
		height: 40px;
		width: 100%;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.searchRightBtn {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #007bff;
		transition: all 0.5s ease;
		border: none;
		outline: none !important;
		&:hover {
			background-color: #006ee5;
		}
		&:focus {
			background-color: #006ee5;
		}
		svg,
		path {
			fill: #fff;
		}
	}
}
