.EmptyState{
	width:  100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	height: 60vh;
	svg{
		width: 100px;
		fill: #007bff;
		margin-bottom: 30px;
	}
	p{
		color: #243b52;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 30px;
		width: 100%;
		max-width: 320px;
		text-align: center;
	}
	.actionBtn{
		width: 150px;
		background-color: #007bff;
		color: #fff;
	}
}

.capitalizedText{
	text-transform: capitalize;
}