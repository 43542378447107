.PhoneInput {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #bcccdc;
    background-color: #fff;
    padding: 12px 10px;
    outline: none;
    font-size: 13px;
    height: 40px;

    &::placeholder {
        color: #868686c2;
        font-size: 13px;
    }
    &:focus {
        border: 2px solid #007bff70;
    }
}

.flexInput {
    display: flex;
    .css-1jke4yk {
        width: 70px !important;
    }
    .country {
        // width: 30px !important;
        background-color: #fdfdfd;

        &:first-child {
            height: 100% !important;
        }
    }

    input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #bcccdc;
        background-color: #fff;
        padding: 12px 10px;
        outline: none;
        font-size: 14px;
        height: 40px;

        &::placeholder {
            color: #868686c2;
            font-size: 13px;
        }
        &:focus {
            border: 1px solid #007bff;
        }
        // &:first-child{
        //     width: 30px;
        // }
        // &:last-child{
        //     width: calc((100% - 30px));
        // }
    }
}
.inputError {
    color: rgba(255, 0, 0, 0.603) !important;
    font-size: 12px;
    margin-top: 2px;
    // margin: 5px 0;
}
