.view__content {
	.popup-content {
		left: unset !important;
		width: 91% !important;
		max-height: 250px;
		overflow-y: scroll;

		.ADropDown__OptionsContainer {
			max-width: unset !important;
		}
	}
}

.edit-payout-class {
	margin-top: 25px;
}

.edit-payout-class .input {
	border: none;
	color: var(--neutral-900, #07121D);
	font-family: Inter;
	font-size: 40px;
	font-style: normal;
	font-weight: 500;
	line-height: 44px;
	text-align: center;
}
