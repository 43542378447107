.labelContainer{
    padding: 8px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    height: 25px;
    font-size: 12px;
    background-color: #FFE3E3;
    color: #E12D39;
    width: unset;
    span{
        padding: 8px 0;
    }

    .additionalFunctionBox{
        margin-left: 20px;
        padding: 8px;
        cursor: pointer;
        transition: all 0.5s ease;
        svg{
            fill: #E12D39;
            cursor: pointer;
            height: 20px;
            width: 20px;
        }
        &:hover{
            background-color: rgb(253, 194, 194);        
        }    
    }

}