@import "~@samedaycustom/assets/responsive-helper.scss";
.__item:first-child {
	border-top: 1px solid #d9e2ec;
}
.__item {
	// display: grid;
	// grid-template-columns: 1rem 1fr 4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// grid-gap: 10px;
	border-bottom: 1px solid #d9e2ec;
	padding: calc(0.8rem + 15px);
	font-size: 0.95rem;
	height: 65px;
	position: relative;
	transition: all 0.2s ease;
	&:hover {
		background-color: #bcccdc10;
	}
	@include respondTo(small-medium) {
		grid-template-columns: 0.5rem 1fr 4.5rem;
	}
	.seenIcon {
		position: absolute;
		display: flex;
		justify-self: right;
		top: 10px;
		left: 10px;
	}
	i {
		content: "";
		background-color: #007bff;
		height: 7px;
		width: 7px;
		border-radius: 50%;
		margin-top: 5px;
	}
}

.active-Message {
	background-color: #F0F7FF !important;
}

.textDetails {
	padding-left: 15px;
	width: 270px;
}

.recentImages {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	svg {
		height: 15px;
		width: 15px;
		margin-right: 5px;
	}
}

.msg-bluedot {
	height: 7px;
	width: 7px;
	border-radius: 50%;
	background-color: #007bff;
}
