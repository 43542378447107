.searchDiv{
	height: 40px;
	border: 1px solid #BCCCDC;
	border-radius: 4px;
	padding: 15px 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.5s ease;
	// max-width: 200px;
	svg{
		width: 20px;
		margin-right: 10px;
	}
}



.searchInput{
	border: none;
	color: #627D98;
	outline: none;
	font-size: 14px;
	width: calc(100% - 30px);	
}

.outlined{
	border: 1px solid #007bff;
}
