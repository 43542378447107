.inputErrorDropdown {
	color: rgba(255, 0, 0, 0.603);
	font-size: 10px;
	position: absolute;
	top: 43px;
}

.customized-select div {
	margin-bottom: 0 !important;
	text-transform: Capitalize;
}

.customized-select > div:nth-child(1) {
	border: 1px solid #bcccdc;
	background-color: #fff;
	background-color: #fff;
	height: 40px !important;
	background-color: #ffffff;
	border-radius: 4px;
}

.react-select__control--is-focused {
	border: 2px solid #007bff !important;
}

.customized-select input {
	&:focus {
		border: 1px solid #007bff;
	}
	// height: 40px;
}

.InputGroup--error .customized-select > div:nth-child(1) {
	border: 1px solid rgba(255, 0, 0, 0.603) !important;
}

.InputGroup--error .customized-select input {
	border: none !important;
}

.react-select--is-disabled,
.react-select__control--is-disabled {
	background-color: #f0f4f8 !important;
	pointer-events: none !important;
	cursor: not-allowed;
}

.react-select__single-value--is-disabled {
	color: #102a43 !important;
}
