.fileItem{
    width: 100%;
    margin-bottom: 20px;
    &__loaderContainer{
        width: 100%;
        height: 2px;
        background: #D9E2EC;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
    }
    &__top{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px !important;
        span{
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #627D98;
        }
    }
    button{
        border: none;
        outline: none;
        cursor: pointer;
    }
    
}
.fileLoader{
    border-radius: 2px !important;
    background-color: #31B237 !important;
    position: relative !important;
    width: 0px;
    height: 2px;
    transition: all 0.5s ease;
}

.uploadedFile{
    background-color: #31B237 !important;
}