.CheckBox {
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #bcccdc;
  display: flex;
  justify-content: center;
  align-items: center;
  //align-items: center;
  transition: all 0.5s ease;
  &--checked {
    background-color: #007bff;
    border: none;
  }
  &--disabled-state {
    opacity: .3;
  }
  .CheckMark {
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;
    transition: all 0.5s ease;

    svg {
      height: 13px;
      width: 13px;
      transform: scale(0);
    }
    &--checked {
      svg {
        height: 13px;
        width: 13px;
        transform: scale(1);
      }
    }
  }
}

.checkgroup {
  display: inline-flex;
  align-items: center;
  span {
    margin-left: 7px;
    font-size: 14px;
  }
}
