.tshirtBoxContainer{
    position: relative;
    .tshirtBox{
        background: #FFFFFF;
        border: 1px solid #627D98;
        border-radius: 4px;
        cursor: pointer;
        padding: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transition: all 0.5s ease;
        // width: 166px;
        height: 166px;
        h1{
            font-size: 13px;
            color: #102a43;
            margin-bottom: 20px;
        }       
    }
    .activeTshirt{
        border: 2px solid #007bff;
    }
}
.transparentAbsolute {
    background-color: transparent;
    position: absolute;
    height: 166px;
    opacity: 0;
    width: 100%;
}

.svgExpand{
    opacity: 0;
    transform: scale(0);
    animation: expand 0.5s ease 0s 1 forwards;
    position: absolute;
    right: 12px;
    bottom: 15px;
}


@keyframes expand{
    from {
        opacity: 0;
        transform: scale(0);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}


.colorGroup{
    position: absolute;
    left: 12px;
    top: 40px;
    display: flex;
    flex-direction: column;
}

.colorBall{
    height: 15px;
    width: 15px;
    margin-bottom: 4px;
    border-radius: 50%;
    transition: all 0.5s ease;
    animation: expand 0.5s ease 0s 1 forwards;
    opacity: 0;
    transform: scale(0);
}

@keyframes expand{
    from{
        transform: scale(0);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}


@media (max-width: 450px){
    .tshirtBoxContainer{
        width: 100%;
        max-width: 200px;
    }
}