.cardWithHeaderContainer {
    .cardWithHeaderContent {
        padding: 2.5rem;
        margin-bottom: 3rem;
        background-color: #fff;
        box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
        --webkit-box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
        --moz-box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
        --o-box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
        border-radius: 5px;
        .__header {
            display: flex;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 1px solid #ddd;
            svg {
                margin-right: 10px;
                margin-left: 20px;
            }
            p {
                font-family: Inter Bold, sans-serif;
                font-size: 1.1rem;
            }
        }
    }
}
