@import "~@samedaycustom/assets/responsive-helper.scss";

.artwork__thumbnail {
	img {
		height: 120px;
		width: 120px;
		object-fit: cover;
		resize: vertical;
		border-radius: 8px;
	}
}

.thumbNailMobile-content {
	@include respondTo(small-medium) {
		height: 250px !important;
		width: 250px !important;
		top: 0;
		left: 0;
	}
}
div[class*="-MenuDialog"] {
	min-width: unset;
	margin-top: -10px;
	box-shadow: rgba(98, 125, 152, 0.24) 0px 6px 18px;
}
.artwork_select {
	// width: 160px;
	outline: none;
	.react-select__control {
		background: transparent;
		border: none !important;
		outline: none !important;
		.react-select__value-container {
			outline: none;
			.react-select__placeholder,
			.react-select__single-value {
				width: 100%;
				color: #007bff;
				font-weight: 500;
				font-size: 14px;
			}
		}
		.react-select__indicators {
			.react-select__indicator {
				span {
					svg {
						path {
							fill: #007bff !important;
						}
					}
				}
			}
		}
	}
	.react-select__menu {
		margin-top: -5px;
	}
}
