:root {
    --translateY: 0px;
}
.VerticalNav {
    width: 100%;
    position: relative;

    .verticalOption {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        z-index: 1;
        padding: 30px;
        cursor: pointer;
        span {
            position: relative !important;
        }
        .__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            .leftIcon {
                margin-right: 20px;
            }
        }
    }

    &__background {
        position: absolute;
        border-right: 4px solid #007bff;
        background-color: #f8f8f8;
        height: 60px;
        width: 100%;
        transition: all 0.5s ease;
        transform: translateY(var(--translateY));
    }
}
