.MessageFile {
  box-sizing: border-box;
  border-radius: 12px 12px 0px 12px;
  background: #ffffff;
  padding: 8px;
  &.MessageFileGroup {
    width: 100%;
    display: grid;
    display: -ms-grid;
    display: -moz-grid;
    display: -webkit-grid;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;

    img {
      height: 40px;
      width: 40px;
      border-radius: 4px;
      margin-right: 5px;
      object-fit: cover;
    }
    .messageFileDetails {
      a {
        text-decoration: none;
        display: block;
        color: #007bff;
      }
      span {
        display: block;
        font-size: 12px;
        text-overflow: ellipsis;
        font-family: Inter;
        font-weight: 500;
        &:nth-child(2) {
          font-weight: lighter;
          color: #627d98;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}
