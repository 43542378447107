.progressItemContainer {
	width: 100%;
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;

	.progressNumberContainerSvg {
		height: 24px;
		width: 24px;
		margin-right: 15px;
		& > svg {
			height: 20px;
			width: 20px;
			opacity: 0;
			transform: scale(0);
			animation: expandProgressNumber 0.5s ease 0.5s 1 forwards;
		}
		.progressNumber {
			height: 24px;
			width: 24px;
			border-radius: 50%;
			background-color: #d9e2ec;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				font-size: 13px;
				color: #102a43;
				font-weight: 600;
			}
		}
	}
	.progressLabel{
			font-size: 14px;
			font-weight: 500;
			color:#007BFF;

	}
	.optionalProgress {
		margin-left: 15px;

			font-weight: 500;
		font-size: 13px;
		color: #31b237;
	}
}

.strikeLabel {
	color: #31b237;
	text-decoration: line-through;
}

@keyframes expandProgressNumber {
	from {
		opacity: 0;
		transform: scale(0);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
