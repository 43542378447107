@import "~@samedaycustom/assets/responsive-helper.scss";

.header {
    &__leftContent{
        opacity: 0;
        pointer-events: none;
    }
    width: calc(100% - 60px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    box-shadow: 0px 2px 2px rgba(217, 226, 236, 0.3);
    padding: 20px 80px 20px 50px;
    // overflow: hidden;
    top: 0;
    left: 60px;
    z-index: 999;
    transition: all 0.5s ease;
    height: 60px;
    &--open{
        left: 180px;
        width: calc(100% - 180px);
    }
    @include respondTo(medium){
        height: 48px;
    }
    &__heading{
        font-size: 18px;
        color: #102a43;
        font-weight: 500;
    }
    &__menuBarContainer{
        height: 60px;
        position: relative;
        margin-right: 20px;
        // width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__menuBar {
        @include respondTo(large-only){
            display: none;
        }
        background-color: #334e68;
        height: 2px;
        border-radius: 10px;
        width: 18px;
        position: relative;
        // position: absolute;
        // right: 20.5px;
        transition: all 0.7s ease 0.3s;  

        &::after,
        &::before {
            content: "";
            position: absolute;
            height: 2px;
            width: 18px;
            border-radius: 10px;
            background-color: #334e68;
            transition: all 0.7s ease 0.3s;
        }

        &::after {
            top: 5px;
        }

        &::before {
            top: -5px;
        }
    }
    // min-height: 90px;

    .notificationList__container {
        .notificationList__content:nth-child(even) {
            background-color: #f0f4f8;
        }
        .notificationList__content:nth-child(odd) {
            background-color: #ffffff;
            border: 1px solid #d9e2ec;
        }
        .notificationList__content {
            display: flex;
            align-items: center;
            padding: 0.5rem 1.2rem 0.5rem 1.2rem;
            box-shadow: 0px 2px 4px rgba(217, 226, 236, 0.8);
        }
    }
    &--navOpen {
        left: calc((9vh + 180px));
        width: calc(100% - (9vh + 180px));
    }

    .locations-selection {
        box-shadow: 0px 2px 2px rgba(130, 154, 177, 0.24);
        border-radius: 4px;
        width: 180px;
        border-color: #fbfcfe;
        text-transform: Capitalize;
        .select__control {
            border-color: #fbfcfe;
            background-color: #fbfcfe;

            .select__value-container {
                color: #102a43;
                .select__placeholer {
                    color: #102a43 !important;
                }
            }
        }
    }

    &__navSmallScreen{
        display: none;
    }

    @include respondTo(medium){
        width: 100%;
        left: 0px;
        padding: 23px;
        &__leftContent{
            opacity: 1;
            pointer-events: all;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    @include respondTo(small-medium){
        &__navSmallScreen{
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }


}

.pageLinks {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.authActionGroup {
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.navItemsGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.navItemCore {
    display: flex;
    align-items: center;
    margin-left: 20px;
    @include respondTo(small-medium){
        display: none;
    }
}

.hiddenOnMobileNav{
    @include respondTo(small-medium){
        display: none;
    }
}
