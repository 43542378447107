.checkboxContainer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    border: 1px solid #627D98;
    height: 40px;
    width: 100%;
    padding: 10px 8px;
    transition: all 0.5s ease;
    border-radius: 4px;
    margin-bottom: 30px;  
    transition: all 0.5s ease;
    cursor: pointer;

    span{
        font-weight: 500;
        color: #102a43;
        font-size: 14px;
        transition: all 0.5s ease;
    }


    &.activeCheck{
        border: 1px solid #007bff;
        background-color: #E5F1FF;
        transition: all 2s ease;
        span{
            color: #007bff;
        }
        .svgExpand{
            opacity: 1;
            transform: scale(1);
            transition: all 0.5s ease;
            animation: expand 0.5s ease 0s 1 forwards;
            top: 12px;
        }
    }
}


@keyframes expand{
    from {
        opacity: 0;
        transform: scale(0);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}