.searchableBox {
  // max-height: 152px;
  width: 240px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(98, 125, 152, 0.24);
  border-radius: 4px;
  padding: 2px;
  padding-bottom: 5px;
  // overflow: auto;
  .inputBlock {
    width: 100%;
    input {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      background-color: #f0f4f8;
      border: none;
      outline: none;
      &::placeholder {
        color: #829ab1;
      }
    }
  }
}

.noResults {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.loaderHolder {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchResultsBox {
  max-height: calc(220px - 40px);
  overflow: auto;
}

.overLayLoader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000010;
}
