@import "~@samedaycustom/assets/responsive-helper.scss";

.card-container {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(217, 226, 236, 0.8);
  border-radius: 16px;
  padding: 20px;

  @include respondTo(small-medium) {
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: wrap;
  }
  .production-content {
    caption {
      margin: 0;
      padding: 0;
      line-height: unset;
    }
  }
}

.orderTableCaption {
  @include respondTo(small-medium) {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    overflow: hidden;
    & > :nth-child(2) {
      margin-top: 15px;
      width: 100%;
      // max-width: 250px;
    }
  }
}

.searchInputGroup {
  position: relative;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  @include respondTo(small-medium) {
    width: 100% !important;
  }
  input {
    border: 1px solid #bcccdc;
    outline: none !important;
    height: 40px;
    width: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .searchRightBtn {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    transition: all 0.5s ease;
    border: none;
    outline: none !important;
    &:hover {
      background-color: #006ee5;
    }
    &:focus {
      background-color: #006ee5;
    }
    svg,
    path {
      fill: #fff;
    }
  }
}
.searchClose {
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  left: 6px;
  height: 10px;
  width: 10px;
  line-height: 20px;
}
.order-filter {
  @include respondTo(small-medium) {
    padding-left: unset;
  }
}
