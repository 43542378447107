@import "@samedaycustom/assets/responsive-helper.scss";

.SidebarItem {
  width: 100%;
  max-width: 300px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 16px;
  transition: all 0.5s ease;
  margin-bottom: 5px;

  &:hover {
    background-color: #e5f1ff;
    .SidebarItem__main {
      .__icon {
        background: unset;
        svg {
          // fill: #007bff !important;
          // path {
          //   fill: #007bff !important;
          // }
        }
      }
      span {
        color: #243b53;
      }
    }
    @include respondTo(medium) {
      background-color: #334e68;
      .SidebarItem__main {
        .__icon {
          background: unset;
          svg {
            fill: #fff !important;
            path {
              fill: #fff !important;
            }
          }
        }
        span {
          color: #fff;
        }
      }
    }
  }
  &__main {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .__icon {
      width: 24px;
      height: 24px;
      /* N50 */

      background: #f0f4f8;
      border-radius: 10.5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      svg {
        fill: #243b53;
        width: 16px;
        height: 16px;
      }
    }

    span {
      color: #243b53;
    }
    @include respondTo(medium) {
      .__icon {
        background: unset;
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
      span {
        color: #fff;
      }
    }
  }
}

.ActiveSideBar {
  background-color: #e5f1ff;
  .__icon {
    background: unset;
    svg {
      fill: #007bff !important;
      path {
        fill: #007bff !important;
      }
    }
  }
  span {
    color: #243b53;
  }

  @include respondTo(medium) {
    background-color: #334e68;
    .SidebarItem__main {
      .__icon {
        background: unset;
        svg {
          fill: #fff !important;
          path {
            fill: #fff !important;
          }
        }
      }
      span {
        color: #fff;
      }
    }
  }
}

.disabled {
  pointer-events: none;
}
