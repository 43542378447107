.InputGroup {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	position: relative;
	width: 100%;
	justify-content: center;
	&--error {
		input,
		textarea {
			border: 1px solid rgba(255, 0, 0, 0.603) !important;
		}
	}

	.textArea {
		resize: none;
		height: 100px !important;
	}

	label {
		font-size: 14px;
		margin-bottom: 4px;
		color: #486581;
	}

	input:disabled {
		background-color: #07070710;
		cursor: not-allowed;
	}

	.input {
		border-radius: 4px;
		border: 1px solid #bcccdc;
		background-color: #fff;
		padding: 12px 10px;
		outline: none;
		font-size: 14px;
		height: 40px;
		&::placeholder {
			color: #627d98;
			font-size: 13px;
		}
		&:focus {
			border: 1px solid #007bff;
		}
	}

	.inputError {
		color: rgba(255, 0, 0, 0.603);
		font-size: 12px;
		margin-top: 2px;
		// margin: 5px 0;
	}
}

.lockSvg {
	position: absolute;
	right: 10px;
	// top: 38px;
	height: 15px;
	width: 15px;
}

.disabledInput {
	background-color: #f0f4f8 !important;
	pointer-events: none;
	cursor: not-allowed;
}
