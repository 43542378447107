.Spinner{
    border: 3px solid #007bff;
    border-radius: 50%;
    border-top-color: #adadad50;
    animation: spin 0.8s infinite linear;
    background-color: transparent;
}

@keyframes spin{
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(360deg);
    }
}