.msgNotif {
	&__container {
		width: 85vw;
		height: 130px;
		margin-left: 30px;
		margin-bottom: 30px;
		max-width: 320px;
		background-color: #fff;
		border: 1px solid #f0f4f8;
		box-shadow: 0px 8px 32px rgba(98, 125, 152, 0.36);
		border-radius: 8px;
		padding: 24px 18px;
		display: grid;
		grid-template-columns: 30px 1fr 15px;
	}
	&__close {
		cursor: pointer;
	}
	&__details {
		h2 {
			font-size: 14px;
			font-weight: 600;
			color: #486581;
			margin-bottom: 4px;
		}
		p {
			height: 40px;
			overflow: hidden;
			word-wrap: break-word;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-height: 18px;
		}
		span {
			color: #007bff;
			border-bottom: 2px solid #007bff;
			cursor: pointer;
			transition: all 0.5s ease;
			&:hover {
				color: #006ee5;
				border-color: #006ee5;
			}
		}
	}
}
