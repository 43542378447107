.select{
    .dropPicker {
        width: 100%;
        height: inherit;
        border-radius: 4px;
        div[class*="-control"] {
            background-color: #fff;
            height: inherit;
            border-color: #9fb3c8;
            border: 1px solid #9fb3c8;
    
            div:last-child {
                padding-right: 6px;
            }
        }
    }
    
}
.inputGrouper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 40px;
    width: 166px;
    border: 1px solid #bcccdc;
    border-radius: 4px;
    // overflow: hidden;
    & > :nth-child(1) {
        width: 45%;
        margin-bottom: 0 !important;
        padding: 10px 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    & > :nth-child(2) {
        width: 55%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 40px;
        & > :nth-child(1) {
            & > * {
                background-color: #d9e2ec !important;
            }
            background-color: #d9e2ec !important;
        }
        & > :nth-child(3) {
            background-color: #fff;
        }
    }
}
