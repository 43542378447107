.TLCard {
  opacity: 0;
  padding-left: 28px;
  border-left: 1px solid #bcccdc;
  position: relative;
  width: 100%;
  margin-left: 12px;
  // margin-top: 12px;
  padding-bottom: 20px;
  &:last-child {
    border-left: none;
  }
  .tlCheckMark {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--store-primary-color, #007bff) ;
    border: 4px solid #fff;
    top: 10px;
    left: 0;
    transform: translate(-50%, -50%);
    box-shadow: 3px 8px 8px -5px rgba(0, 0, 68, 0.18);
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
  }
  .TLCardInfo {
    .tl__sub:last-child {
      margin-bottom: 8px;
    }
    .tl__sub {
      background: #ffffff;
      box-shadow: -4px 4px 32px rgba(98, 125, 152, 0.24);
      border-radius: 8px;
      padding: 5px 16px;
      margin-top: 8px;
      width: fit-content;
      font-weight: 500;
    }
  }
}
.__Trackdropdown {
  border: none;
  width: fit-content;
  .ADropDown__LabelContainer {
    color: #007bff;
    font-weight: 500;
    font-size: 14px;
  }
}
