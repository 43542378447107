@import "@samedaycustom/assets/responsive-helper.scss";

.orderCard {
	max-width: 400px;
	box-shadow: 0px 2px 2px rgba(130, 154, 177, 0.24);
	border-radius: 12px;
	padding: 24px;
	border: 1px solid #f0f4f8;
	position: relative;

	&__DesktopStatus {
		position: absolute;
		left: 40px;
		top: 40px;
	}

	&__info {
		margin-top: 18px;
		// width: 45%;
		margin-left: 4%;
		&__group {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;

			h2 {
				font-weight: 600;
				color: #334e68;
			}
			h3 {
				color: #627d98;
				font-size: 13px;
				font-weight: 500;
				@include respondTo(small-medium) {
					line-height: 17px;
				}
			}
			span {
			}
		}
		&__tripleFLex {
			display: inline-flex;
			justify-content: space-between;
			width: 100%;

			.orderCard__link {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.svg_container {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					background: #F0F4F8;
					border-radius: 40px;
				}
				svg {
					cursor: pointer;
					fill: #007bff;
				}
				p {
					display: flex;
					cursor: pointer;
					color: #007bff;
					font-weight: 500;
				}
			}
		}
	}

	&__imageContainer {
		position: relative;
		> img {
			height: 280px;
			width: 100%;
			object-fit: cover;
		}
	}
	.orderPrice {
		font-weight: 500;
	}
}
