@import "~@samedaycustom/assets/responsive-helper.scss";

.savedlayoutCard_v2 {
  // @include respondTo(small-medium) {
  // 	height: unset !important;
  // 	display: inline-flex;
  // }
  &__image {
    height: 205px;
    width: 200px;
    position: relative;
    border-radius: 4px;
    img {
      // height: 280px;
      width: 200px;
      object-fit: cover;
      z-index: -1;
      // @include respondTo(small-medium) {
      // 	width: unset;
      // }
    }
    // @include respondTo(small-medium) {
    // 	width: unset;
    // 	height: 177px;
    // 	display: flex;
    // 	align-items: center;
    // }
  }
  .__footer {
    @include respondTo(small-medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .ImageSliderContainer__button {
    &--prev {
      left: 12px;
    }
    &--next {
      right: 12px;
    }
  }
}
