.multiSelect{
    &__Container{
        border: 1px solid #bcccdc;
        min-height: 41px;
        width: 100%;
        padding: 2px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        & > :nth-child(n){
            margin: 2px;
        }
    }
    &__ParentBox{
      margin-bottom: 22px !important;
      margin-top: 22px;
      h2{
          font-size: 14px;
          color: rgb(72, 101, 129);
          margin-bottom: 5px;
      }  
    }
   &__loaderHolder{
       display: flex;
       height: 50px;
       width: 100%;
       justify-content: center;
       align-items: center;
   } 
   &__selectSearchResultItems{
       .multiSelectCheckGroup{
           display: flex;
           padding: 5px 10px;
           align-items: center;
           justify-content: flex-start;
           cursor: pointer;
           span{
               margin-left: 10px;
               font-size: 14px;
               margin-bottom: 3px;
               text-transform: capitalize;
               color: #102a43;
           }
       }
   }
}