.dashBoardTile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	svg {
		margin-right: 4px;
		// position: relative;
		// top: -8px;
	}
	.main {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		h2 {
			font-weight: 600;
			font-size: 36px;
			line-height: 44px;
			color: #243b53;
			margin-right: 4px;
		}

		.star {
			margin-bottom: 1px;
		}

		.redHeading {
			color: #ef4e4e;
		}
		.greenHeading {
			color: #31b237;
		}
		.tileLabelText {
			margin-top: 10px;
			color: #486581;
			font-size: 14px;
			line-height: 20px;
		}
		.rateText {
			font-size: 14px;
			font-weight: 500;
			// position: relative;
			// top: -6px;
		}
		.percentText {
			font-size: 20px;
			font-weight: 600px;
			line-height: 34px;
		}
	}
}
