.MiniTable{
	width: 100%;
	margin-top: 20px;
	margin-bottom: 30px;
	&__Header{
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #f0f4f8;
		padding: 10px 20px;
		span{
			font-size: 14px;
			color: #5E6C84;
			font-weight: 500;
		}
		&--edit{
			grid-template-columns: 45px 1fr 1fr;
		}
	}
	&__item{
		width: 100%;
		display: grid;
		grid-template-columns: 50% 50%;
		background-color: #fff;
		padding: 12px 15px;
		border-bottom: 2px solid #bcccdc;
		&--edit{
			grid-template-columns: 45px 1fr 1fr; 
		}
	}
	.imgHolder{
		width: 45px;
		display: flex;
		justify-content: flex-start;
		cursor: pointer;
		svg{
			width: 30px;
		}
	}
	span, p{
		color: #102A43;
		font-size: 14px;
	}

	p{
		width: 100%;
		margin: 20px 0;
		text-align: center;
	}
}