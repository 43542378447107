.stageIndicator{
    margin-top: 0px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .stageBar{
        height: 4px;
        background-color: #bcccdc;
        border-radius: 4px;
        width: 48px;
        margin-right: 8px;
        &:last-child{
            margin-right: 0 !important;
        }
    }
    .activeStage{
        background-color: #3295FF;
    }
    .fadeInClass{
        background-color: #bcccdc;
        animation: fadeIn 0.5s ease 0.5s 1 forwards;
    }
}

@keyframes fadein{
    from{
        background-color: #bcccdc;
    }
    to{
        background-color: #3295FF;
    }
}


