.msgPrev {
	transition: all 0.5s ease;
	&:hover {
		background-color: #d9e2ec10;
	}
	cursor: pointer;
}

.orderPreviewGroup {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 12px;
	padding-top: 10px;
	border-bottom: 1px solid #d9e2ec;
	.rightPreview {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		svg {
			margin-right: 12px;
		}
		.rightPreviewText {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			h2 {
				font-size: 14px;
				color: #102a43;
				font-weight: 500;
				margin-bottom: 4px;
			}
			p {
				// width: 100%;
				max-width: 250px;
				font-size: 14px;
				color: #102a43;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				.recentImages {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					svg {
						height: 15px;
						width: 15px;
						margin-right: 5px;
					}
				}
			}
		}
	}
	.leftPreviewText {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		.leftPreviewLink {
			font-size: 13px;
			color: #007bff;
			transition: all 0.5s ease;
			&:hover {
				color: #006ee5;
			}
		}
	}
}

.orderPreviewGroup:last-child {
	border: none;
}

.previewText {
	font-size: 13px;
	color: #334e68;
	line-height: 16px;
}

.colflexStart {
	justify-content: flex-start;
}
