.foldable__container {
    .__header {
        display: inline-flex !important;
        width: 100%;
    }
    .--content {
        table tbody tr {
            background-color: #fff;
        }
    }
}

// // @keyframes svgAnimation {
// //     from {
// //         transform: rotate(360deg);
// //     }
// //     to {
// //         transform: rotate(-90deg);
// //     }
// // }
