@import "~@samedaycustom/assets/responsive-helper.scss";

.paginationBlock {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
}

.leftPaginationContent {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.dropDownBox {
	width: 70px;
	margin: 0 15px;
	height: 40px;
	& > div {
		margin-bottom: 0;
	}
}

.paginationContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.paginationBtn {
	cursor: pointer;
	color: #102a43;
	margin: 0 15px;
	display: flex;
	align-items: center;
}

.paginationDisabled {
	color: #829ab1;
	cursor: disabled;
	pointer-events: none;
}
.pageBtn {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #102a43;
	transition: all 0.5s ease;
	&:hover {
		color: #000;
	}
	&--active {
		background-color: #e5f1ff;
	}
}

.paginationException {
	@include respondTo(small) {
		display: none;
	}
}
