.selectedDateGroup {
	margin-right: 20px;
	display: flex;
	align-items: center;
	span {
		font-size: 14px;
		margin-right: 15px;
		text-transform: capitalize;
	}
}

.dateGroupingSpan {
	font-size: 13px;
	color: #102a43;
}

.dateGroupingSelectionBox {
	border-radius: 8px;
	min-width: 200px;
	cursor: pointer;
}
.dateGroupingItem {
	cursor: pointer;
	padding: 10px 10px;
	transition: all 0.5s ease;
	text-transform: capitalize;

	&:hover {
		background-color: #bcccdc20;
	}
}

.react-calendar {
	width: 300px;
	max-width: 100%;
	background-color: #fff;
	color: #07121D;
	line-height: 1.125em;
	margin: 20px;
}

.react-calendar__navigation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
	display: none;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
	font-size: 14px;
	color: #07121D;
}
   
.react-calendar__navigation button {
	background: none;
	font-size: 14px;
	padding: 8px !important;
}

.react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays__weekday {
	text-align: center;
	margin-bottom: 12px;
}

abbr[title] {
	text-decoration: none !important;
	color: #627D98 !important;
	font-size: 12px;
}
   
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background: #F0F7FF;
}

.react-calendar__tile {
	padding: 8px;
	height: 38px;
	width: 38px;
}

.react-calendar__tile--now {
	font-weight: 500;
	border-bottom: #07121D 1px solid;
}
   
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #f8f8fa;
}
   
.react-calendar__tile--active {
	background: #007BFF;
	border-radius: 0;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}
   
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #007BFF;
	color: white;
}
   
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #f8f8fa;
}
   
.react-calendar__tile--range {
	background: #F0F7FF;
	border-radius: 0;
}
   
.react-calendar__tile--rangeStart {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background: #007BFF;
	color: white;
}
   
.react-calendar__tile--rangeEnd {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	background: #007BFF;
	color: white;
}

.react-calendar__tile--rangeBothEnds {
	border-radius: 20px;
}

.react-calendar__month-view__days__day--neighboringMonth {
	color: #627D98 !important;
}
