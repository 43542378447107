.design-container {
	display: grid;
	grid-template-columns: 10rem 10rem;
	img:not(.preview) {
		// width: 161.43px;
		// height: 180px;
		object-fit: cover;
	}
	.preview {
		width: 52px;
		height: 52px;
		object-fit: cover;
	}
	.DesignCard {
		&__Select {
			button {
				width: auto;
				padding-left: unset;
			}
			div[class*="-MenuDialog"] {
				box-shadow: rgba(98, 125, 152, 0.24) 0px 6px 18px !important;
			}
		}
	}
}
