.Notif {
  &__dup {
    width: 90vw;
    margin-bottom: 30px;
    margin-left: 30px;
    max-width: max-content;
    padding: 15px 18px;
    display: grid;
    grid-template-columns: 30px 1fr 15px;
    transition: all 0.3s ease;
    border: none !important;
    align-items: start;
    opacity: 0;
    animation: fadeInNotif 0.5s ease 0s 1 forwards;
    & > svg {
      margin-top: 0px;
    }
  }
  &__close {
    margin-top: 3px;
    cursor: pointer;
  }
  &__details {
    h2 {
      font-size: 14px;
      font-weight: 600;
      color: #486581;
      margin-bottom: 4px;
    }
    p {
      // min-height: 40px;
      // padding-bottom: 10px;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 18px;
    }
    span {
      color: #007bff;
      border-bottom: 2px solid #007bff;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        color: #006ee5;
        border-color: #006ee5;
      }
    }
  }
}

.defaultNotification {
  background: #ffffff;
  border: 1px solid #f0f4f8;
  box-sizing: border-box;
  box-shadow: 0px 8px 32px rgba(98, 125, 152, 0.36);
  border-radius: 8px;
  .Notif__close {
    svg {
      fill: #9fb3c8 !important;
    }
  }
  .Notif__details {
    h2 {
      color: #9fb3c8;
    }
  }
}

.infoNotification {
  background: #007bff;
  box-shadow: 0px 8px 32px rgba(98, 125, 152, 0.36);
  border-radius: 8px;
  color: #fff;
  & > svg {
    fill: #fff;
  }
  .Notif__close {
    svg {
      fill: #fff !important;
    }
  }
  .Notif__details {
    h2 {
      color: #fff;
    }
  }
}

.warningNotification {
  background-color: #f0b429;
  box-shadow: 0px 8px 32px rgba(98, 125, 152, 0.36);
  border-radius: 8px;
  color: #fff;
  & > svg {
    fill: #fff;
  }
  .Notif__close {
    svg {
      fill: #fff !important;
    }
  }
  .Notif__details {
    h2 {
      color: #fff;
    }
  }
}

.successNotification {
  background: linear-gradient(180deg, #31b237 0%, #4a9028 100%);
  box-shadow: 0px 8px 32px rgba(98, 125, 152, 0.36);
  border-radius: 8px;
  color: #fff;
  .Notif__close {
    svg {
      fill: #fff !important;
    }
  }
  .Notif__details {
    h2 {
      color: #fff;
    }
  }
}

.errorNotification {
  background-color: #e12d39;
  border: 1px solid #bcccdc;
  box-shadow: 0px 8px 32px rgba(98, 125, 152, 0.36);
  border-radius: 8px;
  color: #fff;
  .Notif__close {
    svg {
      fill: #fff !important;
    }
  }
  .Notif__details {
    h2 {
      color: #fff;
    }
  }
}

@keyframes fadeInNotif {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
