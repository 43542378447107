:root {
  --popup-width: unset;
  --popup-left: initial;
}

.ADropDown {
  &__Container {
    width: 100%;
    height: 40px;
    background-color: #fff;
    border: 1px solid #bcccdc;
    border-radius: 5px;
    color: #102a43;
    border-radius: 4px;
    cursor: pointer;
  }
  &__disabled-state {
    cursor: not-allowed !important;
    opacity: .3;
  }
  &__LabelContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12.5px;
    position: relative;
    &--text {
      margin-right: 12.5px;
    }
    &--icon {
      position: absolute;
      right: 12.5px;
      svg.dropIcon {
        fill: #334e68 !important;
      }
    }
  }
  &__OptionsContainer {
    min-width: 200px;
    padding: 4px;
    // max-height: 200px;
  }
  &__Option {
    width: 100%;
    min-height: 32px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
      background-color: #bcccdc20;
    }
    &.activeOption {
      background-color: #bcccdc30;
    }
  }
}

.ADropDown__LabelContainer--text {
  span,
  p {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
}

.dropdown-content {
  z-index: 99999 !important;
  background-color: #f0f4f8;
  width: var(--popup-width) !important;
  // left: var(--popup-left) !important;
}

.dropdown-checklist-label {
  // max-width: 150px;
}
