.SN--input {
  border: none;
  width: fit-content;
  .ADropDown__LabelContainer {
    &--text {
      margin-right: 20px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 100px;
    }
    .FDropDown__LabelContainer {
      &--icon {
        svg {
          fill: #486581 !important;
        }
      }
    }
  }
}
