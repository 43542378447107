.StoreSettingsCardContainer {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    padding: 25px;
    box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
    --webit-box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
    --moz-box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
    --o-box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
    transition: box-shadow 300ms ease-in-out, transform 300ms ease-in-out;
    min-height: 15rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 2px 25px rgba($color: #000000, $alpha: 0.18);
        --webit-box-shadow: 0 0 25px rgba($color: #000000, $alpha: 0.15);
        --moz-box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.2);
        --o-box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.2);
    }
    .--leftContent {
        display: inherit;
        font-weight: bold;
        .__iconContainer {
            margin-right: 10px;
        }
        span {
            font-size: 1.1rem;
        }
    }
    .__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0.5rem;
        .__header {
            font-family: "Inter Bold", sans-serif;
            font-weight: bold;
        }
        .__body {
            font-family: "Inter Regular", sans-serif;
            color: #757575;
        }
    }
    .--rightContent {
        display: inherit;
        justify-self: flex-end;
    }
}
