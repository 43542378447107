.RadioInput {
    height: 20px;
    width: 32px;
    border-radius: 32px;
    background-color:#BCCCDC;
    position: relative;
    cursor: pointer;
    transition: all 0.5s ease;

    .Knob {
        height: 14px;
        width: 14px;
        border-radius: 32px;
        background-color: #fff;
        // box-shadow: 0px 3px 30px #00000080;
        position: absolute;
        top: 3px;
        left: 3px;
        transform: translateX(0);
        transition: all 0.3s ease;
    }

    &--active {
        background-color: #2395ff !important;
        .Knob {
            //right: 0 !important;
            left: unset !important;
            transform: translateX(15px);
            transition: all 0.3s ease;
        }

        &--wl {
            background-color: #07121D !important;
        }
    }

    &--disabled {
        background-color: #1f85e561 !important;
        cursor: not-allowed;
    }

    
}
