.sentry-error-embed-wrapper {
    .sentry-error-embed {
        .btn {
            background-color: #007bff !important;
            color: #fff;
        }
    }
}

.buttomGroup{
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.TransparentFlex{
    cursor: pointer;
    &:nth-child(1){
        margin-right: 20px;
        padding-left: 0 !important;
    }
    svg{
        margin-right: 14px;
        fill: #102a43;
    }
    span{
        color: #102a43;
        font-weight: 500;
    }

    &--colored{
        span{
            color: #007bff;
        }
        svg{
            fill: #007bff;
        }
    }
}
