@import "~@samedaycustom/assets/responsive-helper.scss";

.Layout {
  min-height: 100vh;
  // position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  animation: pageFadeIn 0.3s ease 0s 1 forwards;
  opacity: 0;
  // overflow: scroll;
}

.SidebarContainer {
  width: 60px;
  height: 100%;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  background-color: var(--nav-bar-color);
  box-shadow: 0px 2px 2px rgba(130, 154, 177, 0.24);

  &--open {
    width: calc(60px + 120px);
  }

  @include respondTo(medium) {
    width: 300px;
    left: -300px;
    // left: 0;
    box-shadow: none;
    &--open {
      width: 300px;
      left: 0;
      box-shadow: 2px 0px 4px rgba(217, 226, 236, 0.8);
      @include respondTo(small-medium) {
        width: 80%;
      }
    }
  }
}

.sideBackGround {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #829ab150;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  &--active {
    opacity: 1;
    pointer-events: all;
    display: none;
    @include respondTo(medium) {
      display: block;
    }
  }
}

.MainContainer {
  @include respondTo(medium) {
    margin-left: (0);
    width: 100%;
    padding-top: 48px;
    .message__container {
      left: 0 !important;
      width: 100% !important;
      margin-top: 48px;
    }
  }
  position: absolute;
  overflow: auto;
  padding-top: 64px;
  min-height: calc(100vh - 60px);
  width: calc(100% - 60px);
  margin-left: 60px;
  transition: all 0.5s ease;
  background-color: #fbfcfe;
  .PageHeader.fixed {
    transition: all 0.5s ease;
  }
  .message__container {
    transition: all 0.5s ease;
  }

  &__sideOpen {
    margin-left: 180px;
    width: calc(100% - 180px);
    .message__container {
      width: calc(100% - 180px) !important;
      left: 180px;
      .message__content {
        width: calc(100%);
      }
    }
    .PageHeader.fixed {
      width: calc(100% - 180px) !important;

      left: 180px;
    }

    @include respondTo(medium) {
      margin-left: 0px;
      width: calc(100%);
      .PageHeader.fixed {
        width: calc(100%) !important;
        left: 0;
      }
      .message__container {
        left: 0 !important;
        width: 100% !important;
      }
    }
  }

  .pageCount {
    overflow: inherit;
    padding: 30px 1.6% 100px;
    min-height: calc(100vh - 64px);
    padding-bottom: 100px;
    width: 100%;
    // opacity: 0;
    // animation: pageFadeIn 1s ease 0s 1 forwards;
  }
}

@keyframes pageFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
