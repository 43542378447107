.transition-group {
	flex: 1;
	position: relative;
}

.switch-wrapper {
	position: relative;
}

.switch-wrapper-enter {
	opacity: 0;
}
.switch-wrapper-enter-active {
	opacity: 1;
	transition: opacity 250ms ease-in;
}
.switch-wrapper-exit {
	opacity: 0;
}
.switch-wrapper-exit-active {
	opacity: 0;
	transition: opacity 250ms ease-in;
}
