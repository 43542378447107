.ADropDown {
  &__Container {
    width: 100%;
    height: 40px;
    background-color: #fff;
    border: 1px solid #bcccdc;
    border-radius: 5px;
    color: #102a43;
    border-radius: 4px;
    cursor: pointer;
  }
  &__LabelContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12.5px;
    position: relative;
    &--text {
      margin-right: 12.5px;
    }
    &--icon {
      position: absolute;
      right: 12.5px;
      svg.dropIcon {
        fill: #334e68 !important;
      }
    }
  }
  &__OptionsContainer {
    min-width: 200px;
    // max-width: 250px;
    padding: 4px;
  }
  &__OptionItem {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.5s ease;
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: #bcccdc20;
    }
    &.activeOption {
      background-color: #bcccdc30;
    }
  }
}

.ADropDown__LabelContainer--text {
  span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
}

.optionsContainer {
  width: 250px;
  background-color: #fff;
  max-height: 300px;
  overflow: hidden;
  border-radius: 4px;
  padding: 5px 0;
  border: #bcccdc50;
}
.popup-overlay {
  height: 100vh;
}
.activeSideBar {
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #07121D;
  border-radius: 0px 6px 6px 0px;
}
