.mainLoaderContainer{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: loaderFadeIn 0.5s ease 0s 1 forwards;
}

.customBar{
  background-color: #007bff !important;
}


.pg-loader {
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    // height: 100%;
    justify-content: center;
  }
  
  .a.loader {
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .dot {
    background: #007BFF;
  
    &,
    &:after {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-left: -4px;
      border-radius: 50%;
      animation: a 2s calc(((var(--i) + var(--o, 0)) / var(--n) - 1) *#{2s})
        infinite;
    }
  
    &:after {
      --o: 1;
      background: none;
      content: "";
    }
  }
  
  
  @keyframes a {
    0%,
    50% {
      transform: scale(0);
    }
  }


  @keyframes loaderFadeIn{
      from{
            opacity: 0;
      }
      to{
          opacity: 1;
      }
  }