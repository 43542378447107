@import "~@samedaycustom/assets/responsive-helper.scss";

.Sidebar {
  &__Header {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    svg {
      // width: 91.86px;
      margin-left: 20px;
    }

    .menuBar {
      background-color: #334e68;
      height: 2px;
      border-radius: 10px;
      width: 18px;
      position: absolute;
      right: 20.5px;
      transition: all 0.7s ease 0.3s;

      &::after,
      &::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 18px;
        border-radius: 10px;
        background-color: #334e68;
        transition: all 0.7s ease 0.3s;
      }

      &::after {
        top: 5px;
      }

      &::before {
        top: -5px;
      }
    }
  }

  &--open {
    .menuBar {
      background-color: transparent;
      &::after {
        top: 0;
        transform: rotate(-45deg);
      }

      &::before {
        top: 0;
        transform: rotate(45deg);
      }
    }
  }

  .sideLinksList {
    padding-top: 25px;
    height: calc(100vh - 70px);
    // overflow-y: auto;
    // overflow-x: hidden;
    @include respondTo(medium) {
      padding-top: 20px;
    }
  }
}

.bottomGroup {
  position: absolute;
  bottom: 1.5vh;
  width: 100%;
  @include respondTo(medium) {
    display: none;
  }
}

.sideBar-smallScreens {
  display: none;
  @include respondTo(medium) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.sideBar__messagingContainer {
  display: none;
  @include respondTo(small-medium) {
    display: flex;
    width: 100%;
  }
}

.flyoutItem {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.5s ease;
  width: 100%;
  cursor: pointer;
  &:hover {
    svg {
      fill: #007bff;
    }
    span {
      color: #007bff;
    }
  }
  svg {
    fill: #627d98;
    margin-right: 13.67px;
    transition: all 0.5s ease;
  }
  span {
    font-size: 14px;
    color: #102a43;
    transition: all 0.5s ease;
  }
  .logoutText {
    color: #ef4e4e !important;
  }
}

.triggerContainer {
  width: 100%;
}

.sideBar__groupHeading {
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
  height: 0;

  &--open {
    opacity: 1;
    pointer-events: all;
    font-weight: 600;
    color: #9fb3c8;
    text-transform: uppercase;
    margin: 10px 16px;
    font-size: 12px;
    letter-spacing: 0.04em;
    display: block;
    height: fit-content;
  }
}

@include respondTo(medium) {
  .hiddenOnMobile {
    display: none;
  }
}

.menuActionBtn {
  position: absolute;
  right: 0;
  transform: translateX(50%) rotate(180deg);
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d9e2ec;
  box-shadow: 0px 2px 2px rgba(130, 154, 177, 0.24);
  transition: all 0.7s ease;
  cursor: pointer;
  outline: none !important;
  @include respondTo(medium) {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    fill: #102a43;
    transition: all 0.7s ease;
    margin: 0 !important;
  }
  &:hover {
    background-color: #007bff;
    svg {
      fill: #fff;
    }
  }
  &--open {
    transform: translateX(50%) rotate(0deg);
    @include respondTo(medium) {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.hideOnDesktop {
  display: none;
  @include respondTo(small-medium) {
    display: inline-block;
    margin-left: 21.25px;
    .acceptOrdersMobile {
      height: 33px;
      padding: 20px;
      background-color: #f0f4f8;
      border-radius: 4px;
    }
  }
}
