.colorPickerContainer{
    width: 200px;
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgba(98, 125, 152, 0.24);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    &__header{
        padding: 9px 10px;
        background-color: #F0F4F8;
        position: sticky;
        top: 0px;
        h2{
            font-size: 13px;
            font-weight: 500;
            color: #243B53;
        }
    }
    &__options{
        padding: 15px 10px;
        margin-top: 0px;
        height: 180px;
        overflow: auto;
    }
    .color-option{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .colorSection{
            display: flex;
            align-items: center;
            .colorBalls{
                height: 20px;
                width: 20px;
                margin-right: 5px;
                border-radius: 50%;
            }
            span{
                color: #243B53;
                font-size: 14px;
                text-transform: capitalize;
            }
        }
    }
}