.tagContainer{
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    span{
        text-transform: uppercase;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.03em;
    }
}


.generalTag{
    background-color: #E5F1FF;
    span{
        color: #007bff;
    }
}

.lineTag{
    background-color: #E5F1FF;
    span{
        color: #007bff;
    }
}

.artTag{
    background-color: #F2EBFE;
    span{
        color: #7A0ECC;
    }
}

.moneyTag{
    background-color: #FFFBEA;
    span{
        color: #DE911D;
    }
}


