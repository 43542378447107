.LLC{
    display: grid;
    grid-template-columns: 40px 1fr 150px 1fr 140px 170px 1fr;
    column-gap: 4vh;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    background-color: #fff;
    color: black;
    margin: 5px 0 20px;
    box-shadow: 0 3px 50px #007BFF10;
    transition: all 0.5s ease;
    &--mini{
      grid-template-columns: 50px 150px 1fr 1fr;
    }
    &__dropdown{
      width: 100% !important;
      max-width: 120px;
      margin: 0 !important;
    }
     .outline--black{
       border: 1px solid #707070;
       svg{
         fill: #1A3A54 !important;
       }
     }
  
    &--header{
      background-color: #EEEEEE;
      box-shadow: none;
      margin-top: 0;
  
      span{
        color: #6E6767 !important;
        display: flex;
        justify-content: flex-start;
      }
    }
  
    span{
      font-size: 13px;
      margin: 0 10px;
      display: flex;
      justify-content: flex-start;
      &.no{
        // min-width: 50px;
      }
      &.name{
        // min-width: 150px;
      }
      &.email{
        // min-width: 200px;
        color: #007bff;
        //border-bottom: 0.5px solid #007bff;
      }
      &.roles{
        // width: 200px;
        // min-width: 130px;
      }
      &.password{
        max-width: 120px;
        // min-width: 150px;
      }
      &.login{
        display: flex;
        justify-content: center;
        text-align: center;
        // min-width: 150px;
      }
  
      &.edit{
        // min-width: 120px;
      }
    }
  
    .Btn{
      width: 100%;
      margin-left: 0;
      &--black{
        color: #000;
        border: 1px solid #000000;
        font-size: 12px;
      }
      &--blue{
        color: #007bff;
        border: 1px solid #007bff;
        font-size: 12px;
        width: 100px !important;
      }
    }
  
  
  
  
  }