.PaginationContainerBlock{
    width: 100%;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.PaginationBlock{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.PaginationText{
    margin-right: 25px;
    span{
        font-size: 14px;
        color: #102a43;
        line-height: 20px;        
    }
}

.paginationActions{
    display: flex;
    & > :nth-child(1){
        margin-right: 10px;
    }
    .paginationActions__box{
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            // height: 20px;
            // width: 20px;
            fill: #102a43;
        }
    }   
}

.disabledIcon{
    cursor: default !important;
    svg{
        fill: #9FB3C8 !important;
    }
}