.price_view {
	display: inline-flex;
	color: #334e68;
	.__first {
		font-family: Inter;
		font-weight: 500;
		font-size: 18px;
		line-height: 19px;
	}
	.__last_container {
		position: relative;
		.last {
			text-transform: uppercase;
			font-size: 14px;
			font-family: Inter;
			font-weight: 500;
			bottom: 2px;
			color: #334e68;
		}
	}
}
