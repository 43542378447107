.ErrorAlert {
	width: 100%;
	border-radius: 5px;
	background-color: #e12d39;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 12px 16px;
	animation: errorAlertFadeIn 0.5s ease 0s 1 forwards;
	svg {
		fill: #fff;
		margin-right: 15px;
	}
	p {
		font-size: 14px;
		color: #fff;
	}
}

@keyframes errorAlertFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
