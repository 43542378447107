.imagehover {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 5000000;
    .__content {
        display: grid;
        grid-template-rows: 0.4fr 0.4fr;
        padding: 0.7rem;
        .--top {
            background-color: #d9e2ec;
            border-radius: 2px;
            height: 180px;
            width: 180px;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                -ms-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
        .--down {
            height: fit-content;
            display: flex;
            min-height: 100px;
            position: relative;
            padding-top: 0.3rem;
            width: 100%;
            justify-content: center;
            align-items: center;
            img {
                height: 140px;
                width: 100px;
                padding: 5px;
                border-radius: 10px;
                top: 50%;
            }
        }
    }
}
