.ButtonMain {
  border-radius: 4px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  width: unset;
  padding: 0 16px !important;
  span {
    font-weight: 500 !important;
    text-transform: lowercase !important;
    &::first-letter {
      text-transform: capitalize !important;
    }
  }

  svg {
    width: 17px;
  }

  .miniSpinner {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-color: #007bff;
    margin-right: 10.5px;
    margin-left: 10px;
  }
}

.disabledBtn {
  cursor: not-allowed;
  // pointer-events: none;
  opacity: 0.72;
}

.visualCue {
  font-size: 14px;
  width: 100px;
}
