// :root{
//   --translateY: 0px;
// }
.VerticalNavigationContianer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 250px;
    .VerticalSliderContianer {
        border-radius: 100px;
        background-color: #bcccdc;
        margin-right: 18px;
        .VerticalSlider {
            height: 32px;
            background-color: #006ee5;
            width: 4px;
            transition: all 300ms ease;
            border-radius: 100px;
        }
    }
    .textGroup {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        cursor: pointer;
        .optionText {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &--active {
                svg {
                    fill: #006ee5;
                }
                span {
                    color: #006ee5 !important;
                }
            }
            svg {
                width: 20px;
                margin-right: 7px;
            }
            span {
                font-size: 15px;
                color: #627d98;
                font-weight: 500;
            }
        }
    }

    // width: 100%;
    // // max-width: 300px;
    // position: relative;

    // .verticalNavigationOption{
    //   height: 60px;
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   cursor: pointer;
    //   background-color: transparent;
    //   z-index: 1;
    //   position: relative;
    //   .flexNavigationOption{
    //     height: 100%;
    //     width: 100%;
    //     display: flex;
    //     justify-content: flex-start;
    //     padding: 20px 30px;
    //     color: black;
    //     svg{
    //       width: 25px;
    //       height: 25px;
    //       margin-right: 20px;
    //       align-items: center;
    //       fill: #868686;
    //     }
    //     span{
    //       position: relative !important;
    //       // font-family: "Helvetica Medium", sans-serif;
    //       font-size: 15px;
    //       color: #101010;
    //     }
    //   }
    //   span{
    //     position: absolute;
    //   }
    // }

    // &__background{
    //   position: absolute;
    //   border-right: 4px solid #007bff;
    //   background-color: #F8F8F8;
    //   height: 60px;
    //   width: 100%;
    //   transition: all 0.5s ease;
    //   // transform: translateY(var(--translateY));
    // }
}
