@import "~@samedaycustom/assets/responsive-helper.scss";

.lineItemCardLoader {
	&__container {
		width: 100%;
		margin: 25px 0;
		margin-top: 0;
	}
	&__top {
		display: flex;
		margin-bottom: 20px;
		& > :nth-child(1) {
			margin-right: 30px;
		}
	}
	&__btm {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: 20px;
		& > :nth-child(1) {
			margin-right: 85px;
		}
	}
}

.lineItemCard {
	&__container {
		width: 100%;
		background: #fbfcfe;
		border: 1px solid #d9e2ec;
		border-radius: 4px;
		margin: 25px 0;
		margin-top: 0;
		// height: 250px;
		padding: 12px;
	}

	&__topContent {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: 18px;
	}

	&__imageContainer {
		margin-right: 25px;
		@include respondTo(small) {
			margin-right: 15px;
		}
	}

	&__img {
		// height: 150px;
		width: 158px;
		object-fit: cover;
	}

	&__mainDetailsGroup {
		span.boldPrice {
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 10px;
		}
		p {
			font-size: 14px;
			margin-bottom: 5px;
		}
	}

	&__btmContent {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		& > * {
			width: 48%;
			// margin-right: 50px;
		}
	}

	&__detailsGroup {
		h2 {
			font-size: 13px;
			color: #829ab1;
			font-size: 13px;
			font-weight: 500;
		}
		p {
			margin-bottom: 5px;
			&:nth-child(1) {
				font-weight: 500;
			}
		}
	}
}
