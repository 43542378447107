.ListItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding: 30px 10px;
    margin-top: 10px;

    &--smaller {
        padding: 20px 0;
        h1 {
            font-size: 14px !important;
            color: #9b9b9b !important;
            text-transform: capitalize;
        }
    }

    .rightContent {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        svg {
            width: 30px;
            fill: #9b9b9b;
        }

        .textContent {
            width: 300px;

            h1 {
                font-family: "Inter Bold", sans-serif;
                font-size: 16px;
                margin-bottom: 5px;
                color: #030303;
                text-transform: capitalize;
            }

            p {
                color: #9b9b9b;
                font-size: 13px;
            }
        }
    }

    .rightContent {
        > :nth-child(1) {
            margin-right: 25px;
        }
    }

    hr {
        height: 1px;
        width: 100%;
        border: none;
        background-color: #adadad50;
        margin-top: 30px;
        position: absolute;
        bottom: 0;
    }

    .Btn {
        border: 1px solid #007bff;
        //width: 100%;
        width: 150px;
        color: #007bff;
        font-size: 13px;
        cursor: pointer;
        margin-left: 0 !important;
    }
}
