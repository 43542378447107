.inputAddress {
	border-radius: 4px;
	border: 1px solid #bcccdc;
	background-color: #fff;
	padding: 12px 10px;
	outline: none;
	font-size: 14px;
	max-width: unset;
	height: 40px;
	width: 100% !important;
	&::placeholder {
		color: #627d98;
		font-size: 13px;
	}
	&:focus {
		border: 1px solid #007bff;
	}
}

.locationInputContainer {
	position: relative;
	.autocomplete-dropdown {
		position: absolute;
		z-index: 99999;
		font-size: 0.9rem;
		width: 100%;
		top: 47px;
		padding: 0;
		.dropdown {
			padding: 0;
			transition: all 500ms ease-in-out;
			z-index: 99999;
			box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
			max-height: 170px;
			background-color: #fff;
			overflow: auto;
			border: 1px solid #ccc;
			border-radius: 4px;
			div {
				padding: 10px 15px;
				z-index: 99999;
			}
		}
	}
}

.loadingBox {
	width: 100%;
	height: 50px;
	padding: 12px 10px;
	height: 40px;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	// box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
	border: 1px solid #007bff;
	position: absolute;
	top: -47px;
	border-radius: 4px;
	right: 0;
	width: 100%;
}

// .lockSvg {
// 	position: absolute;
// 	right: 10px;
// 	top: 38px;
// 	height: 15px;
// 	width: 15px;
// }

.specialLockSvg {
	top: 13px !important;
}

.disabledInput {
	background-color: #f0f4f8 !important;
	pointer-events: none;
	cursor: not-allowed;
}
