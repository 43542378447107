@import "~@samedaycustom/assets/responsive-helper.scss";
.ViewBar {
  &__Mobile-Header {
    display: none;
  }
  &__background {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 3;
    top: 0;
    left: 0;
  }
  &__main {
    position: fixed;
    right: 0;
    top: 60px;
    height: calc((var(--vh, 1vh) * 100) - 60px);
    z-index: 30;
    transform: translateX(100%);
    transition: all 0.5s ease;
    overflow: auto;
    overflow-x: hidden;
    background-color: #fff;
    min-width: 400px;
    @include respondTo(medium) {
      top: 48px;
      min-width: unset;
      width: 100% !important;
    }
    &--show {
      animation: sliderSlideIn 0.5s ease 0s 1 forwards;
    }
    &--noShow {
      animation: sliderSlideOut 0.5s ease 0s 1 forwards;
    }
  }

  &__footer {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    height: 60px;
    box-shadow: 0px -2px 4px rgba(217, 226, 236, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
  }
  &__header {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    height: 60px;
    box-shadow: 0px 2px 4px rgba(217, 226, 236, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    .HorizontalMenuContainer {
      position: relative;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .optionGroupHolder {
      // position: absolute;
      // right: 0;
      // top: 30px;
      padding: 2px;
      height: 300px;
      width: 245px;
      border: 1px solid #d9e2ec;
      box-shadow: 0px 2px 4px rgba(217, 226, 236, 0.8);
      border-radius: 4px;
      background-color: #fff;
      pointer-events: none;
      transition: all 0.5s ease;
      z-index: 3000;
    }
  }

  &__content {
    height: fit-content;
    padding: 30px 20px 50px;
  }
}

.menuBackGround {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 60px);
  width: 100vw;
  z-index: -1;
}

.transparentBtn {
  background-color: transparent !important;
}

.menuOption {
  width: 100%;
  height: 32px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #f2f0fe20;
  }
  span {
    font-size: 14px;
    color: #102a43;
  }
}

.mobile__header_bordered {
  border-bottom: 1px solid #bcccdc;
}

// slide in animation

@keyframes sliderSlideIn {
  from {
    box-shadow: none;
    transform: translateX(100%);
  }
  to {
    box-shadow: -12px 20px 32px rgba(98, 125, 152, 0.24);
    transform: translateX(0);
  }
}

@keyframes sliderSlideOut {
  from {
    box-shadow: -12px 20px 32px rgba(98, 125, 152, 0.24);
    transform: translateX(0);
  }
  to {
    box-shadow: none;
    transform: translate(100%);
  }
}

.noscroll {
  overflow: hidden !important;
}
