@import "~@samedaycustom/assets/responsive-helper.scss";

.NavItem {
	padding: 0 20px;
	display: flex;
	justify-content: center;
	border-right: 1px solid #bcccdc;
	align-items: center;
	height: 30px;
	position: relative;

	&:first-of-type {
		border-left: 1px solid #bcccdc;
	}

	@include respondTo(small-medium) {
		border: none !important;
	}

	span {
		font-size: 14px;
	}

	svg {
		width: 20px;
	}

	svg.dropDownIndicator {
		margin-right: 0;
		margin-left: 5px;
		width: 10px;
		fill: #4b4b4b;
	}

	.notification {
		position: absolute;
		background-color: #007bff;
		color: #fff;
		width: 16px;
		height: 16px;
		border: 4px solid #fff;
		border-radius: 50%;
		left: 32px;
		top: -5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.Avi {
	margin: 0 0 0 20px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	& > svg {
		height: 32px;
		width: 32px;
	}

	.AviImage {
		height: 35px;
		width: 35px;
		border-radius: 50%;
		border: 2px solid #007bff;
		overflow: hidden;
		&--mini {
			height: 40px;
			width: 40px;
		}

		img {
			height: 35px;
			width: 35px;
			object-fit: cover;
		}

		svg {
			width: 100%;
			fill: #e6e6e6;
			object-fit: cover;
		}
	}

	.bottomRow {
		display: flex;
		font-size: 12px;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		svg {
			fill: #007bff;
			width: 10px;
			margin-right: 0;
			margin-left: 10px;
		}
	}

	&:hover {
		.navDropDown {
			opacity: 1;
			pointer-events: all;
			transform: translateY(0);
		}
	}

	.navDropDown {
		position: absolute;
		opacity: 0;
		top: 100%;
		right: 0px;
		padding: 15px;
		background-color: #fff;
		box-shadow: 0 3px 5px #00000010;
		pointer-events: none;
		transform: translateY(-20px);
		transition: all 0.5s ease;
		width: 80vw;
		max-width: 220px;
		z-index: 100000;
		height: 250px;
	}

	.SpinnerContianer {
		position: absolute;
		height: 45px;
		width: 45px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.AviSpinner {
		height: 30px;
		width: 30px;
	}
	.profileDetails {
		width: 100%;
		display: flex;
		border-bottom: 1px solid #85858550;
		align-items: center;
	}

	.sections {
		width: 100%;
		border-bottom: 1px solid #85858550;
		padding: 15px;
	}
	.svgGroup {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		margin: 10px 0;
		svg {
			width: 20px;
			margin-right: 10px;
			fill: #03030390;
		}
		span {
			color: #03030390;
		}

		&:hover {
			svg {
				fill: #007bff;
			}
			span {
				color: #007bff;
			}
		}
	}
	.logoutGroup {
		width: 100%;
		padding: 15px;
		display: flex;
		cursor: pointer;

		&:hover {
			span {
				color: #007bff;
			}
			svg {
				fill: #007bff;
			}
		}

		span {
			color: #03030390;
		}
		svg {
			width: 20px;
			margin-right: 10px;
		}
	}
	.span {
		font-size: 14px;
		color: #030303;
	}

	.textDetails {
		width: calc((100% - 50px));
		padding: 0 10px;
		span {
			display: block;
			margin-bottom: 5px;
		}
	}

	.primary {
		color: #007bff;
		margin-bottom: 5px;
		text-transform: capitalize;
	}

	.dark {
		color: #03030390;
		margin-bottom: 5px;
	}
}

.avi-flyoutItem {
	padding: 6px 8px !important;
}

.nameHeading {
	font-size: 14px;
	text-transform: capitalize;
}
