.InputGroupMenu{
	height: 60px;
	transition: all 0.5s ease
}

.SpecialInput{
	margin-bottom: 30px;
	animation: Expand 0.5s ease 1 forwards;
}



@keyframes Expand{
	from {
		height: 60px;
	}
	to{
		height: 210px;
	}
} 