.UnauthedContainer {
	width: 100%;
	min-height: 100vh;
	background-color: #fbfcfe;
	padding: 30px;
	animation: authPageFade 0.3s ease 0s 1 forwards;
	opacity: 0;
	&__header {
		height: 60px;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0 6%;
		svg {
			height: 37px;
			width: 120px;
		}
	}
	&__mainContentBox {
		padding-top: 100px;
		& > div {
			margin: 0 auto;
		}
	}
}

@media (max-width: 500px) {
	.UnauthedContainer {
		padding: 20px;
		&__mainContentBox {
			padding-top: 30px;
		}
	}
}

@keyframes authPageFade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
