.topPanel__container {
    background-color: #102a43;
    height: 60px;
    transform: translateY(0%);
    transition: all 1s linear;
    opacity: 0 !important;
    width: 100%;
    p {
        font-family: Inter;
        font-weight: 500;
    }
    &--hide {
        opacity: 0 !important;
    }
    &--show {
        opacity: 1 !important;
    }
}