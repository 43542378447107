.dynamicfoldtable {
	&.dtable_container {
		.table_row {
			display: flex;
			flex-grow: 0;
			max-width: 100%;
			flex-basis: 100%;
			&.head {
				padding: 1rem;
				color: #486581;
				p {
					font-family: Inter;
					font-weight: 500;
					font-size: 13px;
					text-transform: capitalize;
				}

				.MuiGrid-root {
					padding-left: 1rem;
					word-break: break-all;
				}
			}
			&__body {
				display: flex;
				flex-grow: 0;
				max-width: 100%;
				flex-basis: 100%;
				.MuiGrid-root {
					padding-left: 8px;
					word-break: break-all;
				}
			}
		}
		table {
			tbody {
				tr {
					vertical-align: top;

					.seperator {
						border-right: 1px solid #bcccdc;
					}
				}
				tr,
				td {
					padding-top: 0.5rem;
					.table_row {
						height: 100%;
						padding: 0px;
						.table_row_td {
							div {
								display: flex;
								flex-direction: column;
								justify-content: center;
								height: 30px;
								padding: 10px;
							}
							div:nth-child(odd) {
								background: transparent;
							}
							div:nth-child(even) {
								background: #f0f4f8;
							}
						}
					}
				}
			}
		}
	}
}
