@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

:root {
	--primary-color: #9446ed;
	--nav-bar-color: #ffff;
}

@font-face {
	font-family: "Inter Medium";
	src: url("./fonts/inter/Inter-Medium.ttf");
}
@font-face {
	font-family: "Inter Bold";
	src: url("./fonts/inter/Inter-Bold.ttf");
}

@font-face {
	font-family: "Inter ExtraBold";
	src: url("./fonts/inter/Inter-ExtraBold.ttf");
}

@font-face {
	font-family: "Inter Regular";
	src: url("./fonts/inter/Inter-Regular.ttf");
}

@font-face {
	font-family: "Inter SemiBold";
	src: url("./fonts/inter/Inter-SemiBold.ttf");
}

*,
*::after,
*::before {
	letter-spacing: 0;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Inter", sans-serif !important;
	letter-spacing: 0;
	font-weight: 400;
	height: 100%;
	width: 100%;
	min-height: -webkit-fill-available;
}

html {
	font-size: 14px;
	height: 100%;
	width: 100%;
}

.subTitle {
	color: #007bff;
	font-family: Helvetica Bold, sans-serif;
}
.subTitle-2 {
	color: #02567a;
	font-family: Nunito;
	font-weight: bold;
}

.swiperight-enter {
	opacity: 0;
	transform: translateX(-20px);
	transition: all 1s, transform 1s;
	transform-style: preserve-3d;
}
.swiperight-enter-active {
	opacity: 1;
	transform: translateX(0px);
	transition: all 1s, transform 1s;
}
.swipeleft-enter {
	opacity: 0;
	transform: translateX(20px);
	transition: all 1s, transform 1s;
	transform-style: preserve-3d;
}
.swipeleft-enter-active {
	opacity: 1;
	transform: translateX(0px);
	transition: all 1s, transform 1s;
}

a,
button {
	user-select: none !important;
}

@supports (-webkit-overflow-scrolling: touch) {
	textarea,
	input {
		font-size: 16px !important;
	}
}
