.optionListItem {
	&:hover {
		background-color: #829ab11f;
	}
}
.optionsList {
	transition: all 0.3s ease-in-out;
	animation: ModaLFadeIn 0.5s ease-in-out;
}

@keyframes ModalFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
