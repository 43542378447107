:root {
  --width: 100px;
}
.HorizontalNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;

  .rightContent {
    display: flex;
  }

  .navigation {
    .navigationList {
      display: flex;
      .navItem {
        // padding: 5px 0px;
        // padding-right: 25px;
        border-right: 0.5px solid #adadad;
        // padding-left: 25px;
        cursor: pointer;
        font-family: Inter;
        font-weight: 500;
        &--active {
          h1 {
            color: #007bff;
          }
        }

        &:hover {
          h1 {
            color: #007bff;
          }
        }

        &:first-child {
          // padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
        h1 {
          display: inline-block;
          // margin-right: 15px;
          transition: all 0.5s ease;
        }
        // span {
        //     color: #adadad;
        //     font-size: 12px;
        // }
      }
    }

    .sliderTrack {
      width: 100%;
      margin-top: 8px;
      position: relative;

      .slider {
        height: 5px;
        width: var(--width);
        background-color: #007bff;
        transition: all 0.5s ease;
        position: absolute;
      }
    }
  }
}
