.SupportBtn{
    // width: 115px;
    height: 36px;
    padding: 8px 12px;
    background: #0062CC;
    box-shadow: 0px 2px 2px rgba(130, 154, 177, 0.24);
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    justify-content: center;
    span{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
    }
    svg{
        margin-right: 10px;
    }
}

.typeOptions{
    width: 180px;
    padding: 4px;
    background-color: #fff;
    .typeOption{
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 32px;
        padding: 8px 10px;
        background-color: #fff;
        transition: all 0.5s ease;
        align-items: center;
        &:hover{
            background-color: #bcccdc20;
        }
        &:active{
            background-color: #bcccdc20;
        }
        svg{
            margin-right: 20px;
        }
        span{
            font-size: 14px;
        }
    }
}

.inputtextAreaContainer{
    textarea{
        height: 120px !important;
        resize: none;
    }
}

.supportContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fadePortalIn{
    animation: fadePortalIn 0.5s ease 0s 1 forwards;
}

.fadePortalOut{
    animation: fadePortalOut 0.5s ease 0s 1 forwards;
}


@keyframes fadePortalIn{
    from{
        opacity: 0;
    }
    to{
        opacity:  1;
    }
}

@keyframes fadePortalOut{
    from{
        opacity: 1;
    }
    to{
        opacity:  0;
    }
}