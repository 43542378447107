@import "~@samedaycustom/assets/responsive-helper.scss";
.message__input {
  background-color: #fff;
  position: relative;
  .MobileSendBtn {
    display: none;
  }
  @include respondTo(small-medium) {
    height: 100px;
    padding-top: 16px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 6px;
    .Mobile__inputDropDown {
      height: 20px !important;
      border: none;
    }
    .AttachFileBtn {
      background-color: #fff;
      height: 20px;
    }
    .Desktop__button {
      display: none;
    }
    .MobileSendBtn {
      display: inline-block;
      height: 48px;
      width: 50px;
      background-color: #007bff;
      cursor: pointer;
      border: none;
      outline: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #006ee5;
      }
    }
    .TextArea__OuterParent {
      padding: 0 !important;
    }
    .TextArea__InnerParent {
      height: 48px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      textarea {
        min-height: 48px;
        // min-height: unset !important;
        border-radius: 0px;
        padding: 4px 8px;
        outline: #007bff !important;
      }
      @include respondTo(small-medium) {
        overflow: visible !important;
        align-items: flex-end;
        textarea {
          max-height: 80px;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
          overflow: auto;
        }
        & > :nth-child(2) {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px !important;
          border: 1px solid #007bff;
        }
      }
    }
  }
  @include respondTo(small) {
    padding: 10px !important;
    .AttachFileBtn {
      background-color: #fff;
      p {
        display: none;
      }
    }
  }
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    p {
      font-size: 16px;
      color: #102a43;
      font-weight: 500;
    }
    svg {
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
  &-dropdownGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
    .dropdownItemGroup {
      width: 48%;
      h2 {
        font-size: 14px;
        color: #486581;
        margin-bottom: 4px;
        font-weight: 500;
      }
      &__dropdown {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
    }
  }
  &-textBox {
    h2 {
      font-size: 14px;
      color: #486581;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }
  &-mobileContainer {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    transition: all 0.5s ease;
    @include respondTo(small-medium) {
      opacity: 1;
      pointer-events: all;
      .message__input-background {
        background-color: #829ab150;
        height: 100vh;
        width: 100%;
        position: fixed;
      }
    }
  }
  &-mobileBox {
    position: fixed;
    width: 100%;
    height: 92vh;
    max-height: 600px;
    border-radius: 32px 32px 0px 0px;
    bottom: 0;
    left: 0;
    transform: translate(0, 100%);
    background-color: white;
    padding: 24px;
  }
  &-checkitem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    span {
      margin-left: 10px;
    }
  }
  &-fileBtn {
    margin-top: 5px;
    height: 2.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.bottomOptionsGroup {
  margin-top: 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.slideUpAnimation {
  animation: mobileTabSlideUp 0.5s ease 0s 1 forwards;
}

.slideDownAnimation {
  animation: mobileTabSlideDown 0.5s ease 0s 1 forwards;
}

@keyframes mobileTabSlideUp {
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes mobileTabSlideDown {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 100%);
  }
}

// .selectedArtworkGrouping {
// 	position: absolute;
// 	padding: 20px;
// 	padding-top: 10px;
// 	padding-right: 10px;
// 	top: 0;
// 	left: 25px;
// 	background-color: #fff;
// 	box-shadow: 0px 4px 24px rgba(217, 226, 236, 0.5);
// 	max-width: 321px;
// 	border: 1px solid #d9e2ec;
// 	max-height: 300px;
// 	opacity: 0;
// 	animation: fadeArtIn 0.5s ease 0s 1 forwards;
// 	display: flex;
// 	flex-wrap: wrap;
// 	overflow: auto;
// 	transform: translateY(-110%);
// 	& > * {
// 		margin-right: 10px;
// 		margin-top: 10px;
// 	}
// 	& > svg {
// 		margin: 0;
// 		border-radius: 50%;
// 		position: absolute;
// 		right: 0;
// 		top: 0;
// 		transform: translate(50%, -50%);
// 		box-shadow: 0px 2px 4px #102a4320;
// 		cursor: pointer;
// 	}

// 	// height: 10000px;
// 	// width: 700px;
// }

@keyframes fadeArtIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.selectedImageHolder {
  margin-top: 20px;
  max-height: 300px;
  max-width: 80%;
  padding: 20px;
  padding-top: 10px;
  padding-right: 10px;
  overflow: auto;
  & > * {
    margin-right: 10px;
    margin-top: 10px;
  }
}

.slideUpContent__holder {
  height: calc(100% - 30px);
}

.messageDesktopbtn {
  background-color: #007bff;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 5px;
  padding: 10px 15px;
  &:hover {
    background-color: #006ee5;
  }
  @include respondTo(small-medium) {
    display: none !important;
  }
}

.textarea {
  min-height: 48px;
  // min-height: unset !important;
  border-radius: 0px;
  padding: 4px 8px;
  outline: #007bff !important;
}
@include respondTo(small-medium) {
  .textarea {
    overflow: visible !important;
    align-items: flex-end;
    max-height: 80px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    overflow: auto;
    & > :nth-child(2) {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border: 1px solid #007bff;
    }
  }
}

.mainTextArea {
  padding: 8px 4px 8px !important;
}
