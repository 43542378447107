.settingsHeaderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .__header {
        display: flex;
        flex-direction: row;
        width: inherit;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #3631315f;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
}
