.MOrderLoader{
    &__container{
        width: 100%;
    }
    &__row{
        .row1{
            & > :nth-child(1){
                width: 70%;
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
        margin-bottom: 15px;
        & > :nth-child(n) {
            width: 33%;
        }
        & > :last-child{
            width: 11%;
        }
    }
}   