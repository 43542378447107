@import "~@samedaycustom/assets/responsive-helper.scss";
.fixed {
  position: fixed;
  top: 60px;
  left: 60px;
  background-color: #fff;
  width: calc(100% - 60px) !important;
  height: 64px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 24px rgba(217, 226, 236, 0.2);
  padding: 0 42px;
  z-index: 25;
  .mainContent {
    height: 100%;
    .leftContent {
      height: 100%;
    }
    .rightContent {
      height: 100%;
    }
  }
}

.fixed.PageHeader {
  @include respondTo(medium) {
    display: flex !important;
    left: 0;
    width: 100% !important;
    top: 46px;
    padding: 0 11px !important;
  }
}

.PageHeader {
  width: 100%;
  @include respondTo(small-medium) {
    display: none;
  }

  .mainContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .backText {
        margin-left: 8px;
        font-size: 14px;
        color: #627d98;
        font-family: Inter;
        font-weight: 500;
      }

      .backButton {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 0 !important;
        height: inherit;
        border: none;
        outline: none;

        svg {
          fill: #627d98;
          width: 20px;
        }
      }

      .line {
        height: inherit;
        width: 1px;
        background-color: #d9e2ec;
        margin: 0 20px;
        @include respondTo(medium) {
          margin: 0 8px;
        }
      }

      h1 {
        font-size: 16px;
        font-weight: 500;
        color: #243b53;
      }
    }

    .rightContent {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .subContent {
      margin-top: 5px;
      span {
        color: #007bff;
        font-size: 10px;
      }
    }
  }
}
