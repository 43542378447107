.infoCheckSelectionContainer {
    .selectContainer {
        display: flex;
        align-items: center;
        background-color: #d0d0d0;
        color: #fff;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 250px;
        height: 35px;
        border-radius: 20px;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        .selectContent {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        span:last-child::before {
            display: flex;
            align-items: center;
            justify-content: center;
            content: "";
            height: 20px;
            width: 20px;
            background-color: #fff;
            border-radius: 50%;
            transition: all 300ms ease-in-out;
        }
        span:first-child {
            padding-left: 1rem;
            align-self: center;
            transform: translateX(20px);
            min-width: 12rem;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            transform: scaleY(1.15) scaleX(1.02);
            span:first-child {
                transform: scale(0.95) translateX(20px);
            }
            span::before {
                transform: scaleX(0.8) scaleY(0.7);
                margin-right: 10px;
            }
        }

        &.checked {
            transform: scaleY(1.15) scaleX(1.02);
            background-color: #007bff;
            span:first-child {
                transform: scale(0.9) translateX(-15px);
            }
            span:last-child::before {
                content: "x";
                background-color: #0344a0c0;
                transform: scaleX(0.8) scaleY(0.7);
                margin-right: 10px;
            }
        }
    }
    .childrenContainer {
        transform: scale(0);
        transition: all 200ms ease-in-out;
        min-width: 250px;
        &.checked {
            display: flex;
            transform: scale(1) translateY(23px);
        }
    }
}
