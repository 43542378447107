.dropPicker{
    background-color: #D9E2EC;
    position: relative;
    left: -1px;
    & > div{
      background-color: #D9E2EC;
      border:none !important;
      border-radius: 0;
      color: #102a43;
      &:hover{
        background-color: #D9E2EC;
        border: none;
      }
    }
}

.dropInputGrouping{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 166px;
    border: 1px solid #bcccdc;
    // border-right: none;
    background-color: #D9E2EC;
    border-radius: 4px;
    height: 100%;
    // overflow: hidden;
    & > :nth-child(1){
        width: 45%;
        margin-bottom: 0 !important;
        padding: 10px 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        height: 40px;
    }
    & > :nth-child(2){
        width: calc(55% + 1px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 40px;
        & > :nth-child(1){
          & > *{
            background-color: #D9E2EC !important;
          }
          background-color: #D9E2EC !important;
        }
        & > :nth-child(3){
          background-color: #fff;
        }
    }

}