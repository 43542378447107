.FDropDown {
  &__Container {
    width: 100%;
    min-width: 250px;
    height: 41.75px;
    background-color: #f0f4f8;
    color: #102a43;
    border-radius: 4px;
    cursor: pointer;
    z-index: 100;
  }
  &__LabelContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12.5px;
    position: relative;
    &--text {
      margin-right: 12.5px;
      width: calc(100% - 30px);
      span {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &--icon {
      position: absolute;
      right: 12.5px;
      svg.dropIcon {
        fill: #334e68 !important;
      }
    }
  }
}

.searchList-Item {
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: #bcccdc20;
  }
}

.active--item {
  background-color: #bcccdc40 !important;
  border: 1px solid #bcccdc20;
}

.popup-content {
  z-index: 100 !important;
}

.noWrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.miniFlyoutItem {
  text-transform: capitalize;
  color: #102a43;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
