.whiteBackGround{
    height: 100vh;
    width: 100vh;
    background-color: #fff;


    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        z-index: 1;
        animation: rotate 2s linear infinite forwards;
    }
}



@keyframes rotate{
    from{
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}