:root{
    --rangeEnd: 0%;
}

.progressBarGroupContainer{
    width: 100%;
    margin-bottom: 50px;
    .progressTopLevel{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2{
            font-size: 16px;
            color: #102a43;
        }
        .percentContainer{
            padding: 2px 6px;
            border-radius: 8px;
            background-color: #006ee5;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                color: #fff;
                font-size: 13px;
            }
        }
    }
    .progressbarOuter{
        margin-top: 16px;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background-color: #d9e2ec;
        .progressbarInner{
            height: 8px;
            border-radius: 4px;
            background-color: #31B237;
            transform: scaleX(0);
            width: 0;
            animation: progress 0.5s ease 0.5s 1 forwards;
            transform-origin: left;
        }
    }
}


@keyframes progress{
    from{
        width: 0;
        transform: scaleX(0);
    }
    to{
        width: var(--rangeEnd);
        transform: scaleX(1);
    }
}