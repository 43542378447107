.ViewCard{
	background: transparent;
	// box-shadow: 0px 2px 4px rgba(217, 226, 236, 0.8);
	border-radius: 4px;
	padding: 15px;
	margin-bottom: 15px;
	&__header{
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 15px;
		h2{
			color: #243B53;
			font-size: 14px;
		} 
		svg{
			width: 20px;
			height: 20px;
			position: relative;
			top: -2px;
			cursor: pointer;
			margin-left: 10px;
		}
	}
}