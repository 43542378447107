@import "~@samedaycustom/assets/responsive-helper.scss";
.table-container {
	background-color: #fff;
	& > div:nth-child(3):not(.pagination-controller) {
		display: none;
	}
}
.status__tag {
	width: 100%;
	max-width: 150px;
	border-radius: 4px !important;
	@include respondTo(small) {
		max-width: 120px;
	}
}
.tag__text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}

.alt-table-wrapper-styles {
	position: relative;
}

.alt-table-wrapper-styles table {
	width: 100vw;
}

.alt-table-overflow-styles {
	overflow-x: auto;
}

.records-range,
.pagination-controller .popup-content, 
.record-range-option.ADropDown__OptionsContainer {
	width: 75px !important;
}

.record-range-option .ADropDown__Option {
	width: 65px !important;
}
