@import "~@samedaycustom/assets/responsive-helper.scss";
.Notes__ViewBar {
  .note__messageView__ContentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    padding: 10px 20px;
    @include respondTo(small-medium) {
      height: calc(100vh - 208px);
      height: calc((var(--vh, 1vh) * 100) - 231px);
      padding: 5px 10px;
    }
  }
  .note__chatListContainer__fluid {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;

    &__fluid {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
@include respondTo(medium) {
  .Notes__ViewBar {
    width: 100vw;
    top: 0;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    opacity: 0;
    transform: translate(0, 0);

    // .messageView__ContentContainer {
    //   height: calc((var(--vh, 1vh) * 100) - 140px) !important;
    // }
    .ViewBar {
      &__header {
        box-shadow: 0px 4px 24px rgba(217, 226, 236, 0.2) !important;
        padding: 0 15px;
        z-index: 99;
      }
      &__Mobile-Header {
        display: none;
      }
    }
    &.ViewBar__main {
      width: 100% !important;
      height: 100vh;
      height: calc((var(--vh, 1vh) * 100));
      min-height: unset;
      border-radius: 32px 32px 0 0;
      z-index: 10000;
      top: 48px;
      min-width: unset;
      left: 0;
      transform: translate(0, 0);
      // overflow: hidden;
    }

    .message__view {
      height: calc(100vh - 208px);
      height: calc((var(--vh, 1vh) * 100) - 208px);
    }
    .message__view--MobileShow {
      bottom: 72px;
      cursor: pointer;
    }
  }

  .NotesFadeIn {
    animation: fadeInPage 0.5s ease 0s 1 forwards;
  }
  .NotesFadeOut {
    animation: fadeOutPage 0.5s ease 0s 1 forwards;
  }
}

@keyframes fadeInPage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutPage {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.Notes__ViewBar {
  .messageView__ContentContainer {
    height: calc((var(--vh, 1vh) * 100) - 140px);
  }
}
