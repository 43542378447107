.closedSupportBtn {
    span {
        opacity: 0;
        pointer-events: none;
        display: none;
    }
    svg {
        margin-right: 0px !important;
    }
    // border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-left: 13.25px;
}

.customSptBtn {
    margin-left: 13.25px;
}
