@import "~@samedaycustom/assets/responsive-helper.scss";

.datePicker__btnGroup {
  display: flex;
}

.datePickerGroup {
  display: flex;
  margin: 0 20px;
  opacity: 0;
  animation: fadeIn 0.5s ease 0s 1 forwards;
  z-index: 500;
  @include respondTo(small-medium) {
    flex-direction: column;
    .datePicker__btnGroup {
      margin-top: 15px;
    }
  }

  .dateInputGrouping {
    display: flex;
    margin-right: 20px;
  }
  .timeBlockdiv {
    width: 90px;
    &:nth-child(1) {
      border-right: 1px solid #9fb3c8;
    }
  }

  .dateActionBtn {
    width: 90px;
    &.marginRight {
      margin-right: 15px;
    }
  }
  .date-error {
    color: #ef4e4e;
    font-size: 12px;
    margin-top: 5px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.PDisabled {
  background-color: #007bff40 !important;
}
