.NB {
  &--container {
    width: calc(100% - 20%);
    margin-bottom: 12px;
    padding: 8px 16px 8px 12px;
    background: #e5f1ff;
    border: 1px solid #7fbdff;
    border-left: 4px solid #007bff;
    box-sizing: border-box;
    border-radius: 0px 16px 0px 0px;
  }
  &--header {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      text-transform: capitalize;
      color: #486581;
    }
  }
  &--body {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  &--footer {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    .hashTag {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #007bff;
    }

    .actionTab {
      display: inline-flex;
      svg:first-child {
        margin-right: 20px;
      }
      svg {
        cursor: pointer;
      }
    }
  }
}
