@import "~@samedaycustom/assets/responsive-helper.scss";

.Time_line_container {
	.ViewBar__content {
		padding: 0 !important;
	}
}

.mobileTimeLineHeader {
	display: none;
}

.TLDesktopDropDown {
	border: none;
	color: #fff !important;
	background-color: rgba(255, 255, 255, 0.3);
	svg.dropIcon {
		fill: #fff !important;
	}
}
.desktopTLHeader {
	position: sticky;
	top: 60px;
	z-index: 99999;
}
@include respondTo(small-medium) {
	.Time_line_container {
		.ViewBar__Mobile-Header {
			display: none;
		}
		.ViewBar__content {
			height: 100%;
			max-height: 600px;
		}
	}

	.mobileTimeLineHeader {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		// position: sticky;
		top: 0;
		// height: 77px;
	}
	.mlItem__left {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
	.desktopTLHeader {
		display: none;
	}
	.TLMobileDropDown {
		margin-left: 20px;
		border: none;
		color: #007bff !important;
		svg.dropIcon {
			fill: #007bff !important;
		}

		//    width: 100px;
	}
}
