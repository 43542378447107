.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  opacity: 0;
  pointer-events: none;

  &--show {
    pointer-events: all;
    animation: ModalFadeIn 0.5s ease 0s 1 forwards;
  }
  &--noShow {
    pointer-events: none;
    animation: ModalFadeOut 0.5s ease 0s 1 forwards;
  }

  &__Overlay {
    position: absolute;
    z-index: 11000;
    background-color: #243b538f;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // opacity: 0;
  }

  &__ContentHolder {
    position: absolute;
    z-index: 12000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    border-radius: 8px;
    max-width: 720px;
    // overflow: hidden;
    max-height: 90vh;

    &.largeModal {
      max-width: 600px !important;
    }
    &.smallModal {
      max-width: 480px;
    }

    .contentTop {
      padding: 25px;
      background-color: #fff;
      padding-bottom: 20px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      & > div:nth-child(2) {
        // max-height: calc(60vh);
        // padding-bottom: 20px;
        // overflow: auto;
      }
    }

    .Modal__ContentHolder__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid #d9e2ec;
      h2 {
        font-size: 16px;
        color: #102a43;
        font-weight: 500;
      }
      .closeContainer {
        cursor: pointer;
        svg {
          width: 25px;
        }
      }
    }

    .contentBottom {
      height: 72px;
      padding: 0 24px;
      background-color: #f0f4f8;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .roundedContent {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  &__ContentHolder {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 12000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(46, 47, 48, 0.11);
    border-radius: 8px;
    // min-height: 300px;
    // min-width: 400px;

    &__text {
      position: absolute;
      z-index: 12000;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(46, 47, 48, 0.11);
      border-radius: 8px;
      max-width: 600px;
      max-height: 90vh;
      width: 600px;
    }
    .Modal__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #d9e2ec;
      margin: 1.1rem;
      padding-bottom: 0.6rem;
    }
    .Modal__header.no-border {
      border-bottom: none !important;
    }
    .Modal__body {
      margin: 1.5rem;
      margin-top: 0;
      overflow-y: auto;
      border-radius: inherit;
      // min-height: calc(300px - 100px);

      &.success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &.text {
        margin: 0;
      }
    }
    .Modal__footer {
      display: flex;
      flex-direction: row;
      padding-right: 0.5rem;
      justify-content: flex-end;
      align-items: center;
      min-height: 70px;
      background: #f0f4f8;
      border-radius: 0px 0px 8px 8px;
      p {
        font-family: Inter;
        font-weight: 500;
      }
      button {
        margin: 1rem;
      }
    }

    .Modal__footer.no-background {
      background-color: inherit !important;
    }

    .Modal__footer.full-width {
      display: block;
      width: 100%;
      padding-right: 10%;
      padding-left: 10%;
      background-color: inherit;

      button {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

.cancelBtn {
  width: 130px;
  margin-right: 20px;
  background-color: transparent;
  color: #486581;
}

@keyframes ModalFadeIn {
  from {
    opacity: 0;
    // pointer-events: none;
  }
  to {
    opacity: 1;
    // pointer-events: all;
  }
}

@keyframes ModalFadeOut {
  from {
    opacity: 1;
    // pointer-events: all;
  }
  to {
    opacity: 0;
    // pointer-events: none;
  }
}

.cancel-btn-updated {
  border-radius: 6px;
    border: 1px solid #BCCCDC !important;
    background: #FFF !important;
    color: #07121D;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: all .7s;
}
