.MessageLoader{
    &__container{
        width: 100%;
        padding: 14px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: calc(100% - 50px);
        & > :nth-child(1){
            width: 60%;
            margin-bottom: 2px;
        }
        & > :nth-child(2){
            width: 80%;
        }
    }
}