.contentContainer {
    display: grid;
    grid-template-columns: 2rem repeat(auto-fit, minmax(5rem, 1fr));
    grid-gap: 5rem;
    align-items: center;
    width: inherit;
    margin-bottom: 3rem;

    .inputGroup {
        height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .inputGroupRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}
