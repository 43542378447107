.orderNotifContent {
	margin-top: 10px;
	width: 100%;
	.topNotifInfo {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		& > :nth-child(1) {
			margin-right: 37px;
		}
		.notificationDetail {
			h2 {
				color: #fff;
				font-size: 13px;
				font-weight: 500;
			}
			p {
				font-weight: 500;
				color: #fff;
				span {
					font-size: 12px;
					color: #fff;
					border: none;
					margin: 0 !important;
				}
			}
		}
	}
	.notifBtnGroup {
		width: 100%;
		display: flex;
		margin-top: 12.5px;
		.viewOrder {
			background-color: #31b237;
			border-radius: 2px;
			color: #fff;
			cursor: pointer;
			transition: all 0.3s ease;
			padding: 3.8px 8px !important;
			height: 28px !important;
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			&:hover {
				background-color: #18981d;
			}
			span {
				color: #fff;
				font-weight: 500;
				border-bottom: none !important;
			}
		}
		.viewLater {
			background-color: transparent !important;
			margin-left: 25px;
			span {
				color: #fff;
				font-weight: 500;
				display: inline-block;
				text-decoration-line: underline;
				border-bottom: none !important;
			}
		}
	}
}
