@import "~@samedaycustom/assets/responsive-helper.scss";

.FullLoader {
	height: calc(100vh - 60px);
	position: fixed;
	top: 60px;
	left: 60px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	@include respondTo(small-medium) {
		left: 0px;
	}
	& > * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
