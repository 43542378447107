.search {
    transition: all 0.5s ease;
    transition-delay: 300ms;
    display: flex;
    border-right: 0.5px solid #adadad;
    border-left: 0.5px solid #adadad;
    padding: 0 20px;
    height: 70%;
    position: relative;
}

.search svg {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
}

.search input {
    width: 0;
    color: #707070;
    font-size: 13px;
    margin-left: 10px;
    border: none;
    transition: all 0.5s ease;
    transition-delay: 300ms;
    outline: none;
    transform: scaleX(0);
}

.search:hover {
    border-bottom: 1px solid #707070;
    border-right: none;
    border-left: none !important;
    padding-left: 35px;
}

.search:hover input {
    width: 150px;
    transform: scaleX(1);
}
