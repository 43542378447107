@import "~@samedaycustom/assets/responsive-helper.scss";
.side_container {
  @include respondTo(medium) {
    position: static !important;
    width: 100% !important;
    max-height: unset !important;
  }

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .row {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  div {
    .popup-content {
      left: 124px !important;
    }
  }
  .row-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &.-x1 {
      grid-template-columns: 1fr;
    }

    .col {
      padding-bottom: 1.5rem;
      color: #334e68;
      // line-height: 20px;
      p:first-child {
        color: #829ab1;
        font-family: Inter;
        font-weight: 500;
        font-size: 13px;
      }
      p:last-child {
        // max-width: 93px;
        font-family: Inter;
        // font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
