.tag-container {
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
}

.filterContainer {
    position: absolute;
    left: -50px;
    right: 0;
    margin-top: 1rem;
    z-index: 9999;
    min-width: 240px;
    padding: 10px;
    border-radius: 8px;

    .Filter__content{
        .popup-content {
            width: 100% !important;
            left: 0px !important;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
    }
    p {
        font-family: Inter;
        font-weight: 500;
    }

    .__content {
        .datePicker:last-child {
            border-right: none;
        }
        .datePicker {
            background: #f0f4f8;
            border-radius: 4px 0px 0px 4px;
            padding-right: 10px;
            border-right: 1px solid #ddd;
            font-family: Inter;
            width: 100%;
        }
        .calendar {
            background-color: #fff;
            box-shadow: 0px 6px 18px rgba(98, 125, 152, 0.24);
        }
    }
}

.Filter__dropdown{
    background: #F0F4F8;
    border: none;
    height: 40px;
}